import { __decorate as t } from "tslib";
import { LitElement as e, html as i } from "lit";
import { I18nService as o, baseStyles as r, i18n as s, property as n, event as l } from "@cds/core/internal";
import { GridColumnSizeController as p } from "./grid-column-size.controller.js";
import { GridColumnPositionController as a } from "./grid-column-position.controller.js";
import d from "./grid-column.element.scss.js";
class c extends e {
  constructor() {
    super(...arguments);
    this.i18n = o.keys.grid, this.resizable = !1, this.type = "", this.position = "", this.gridColumnSizeController = new p(this), this.gridColumnPositionController = new a(this);
  }
  get grid() {
    return this.parentElement;
  }
  render() {
    return i`<div class="private-host" focusable><slot>${"action" === this.type ? i`<span cds-layout="display:screen-reader-only">${this.i18n.action}</span>` : ""}</slot>${this.resizable ? i`<cds-internal-split-handle @cdsTouchStart="${() => this.grid.scrollLock = !0}" @cdsTouchEnd="${() => this.grid.scrollLock = !1}"><input type="range" step="10" .ariaLabel="${this.i18n.resizeColumn}" @change="${t => this.resizeChange.emit(t.target.valueAsNumber)}"></cds-internal-split-handle>` : i`<cds-internal-split-handle readonly="readonly"></cds-internal-split-handle>`}<div class="line"></div></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.slot = "columns";
  }
}
c.styles = [r, d], t([s()], c.prototype, "i18n", void 0), t([n({
  type: String
})], c.prototype, "width", void 0), t([n({
  type: Boolean
})], c.prototype, "resizable", void 0), t([n({
  type: String
})], c.prototype, "type", void 0), t([n({
  type: String
})], c.prototype, "position", void 0), t([l()], c.prototype, "resizeChange", void 0);
export { c as CdsGridColumn };
