import { __decorate as t } from "tslib";
import { LitElement as e, html as s } from "lit";
import { I18nService as a, setAttributes as r, syncDefinedProps as n, baseStyles as o, property as i, state as c, id as l, i18n as p, querySlot as d, querySlotAll as h, closable as u } from "@cds/core/internal";
import { ClarityIcons as g } from "@cds/core/icon/icon.service.js";
import { infoStandardIcon as y } from "@cds/core/icon/shapes/info-standard.js";
import { successStandardIcon as m } from "@cds/core/icon/shapes/success-standard.js";
import { warningStandardIcon as b } from "@cds/core/icon/shapes/warning-standard.js";
import { errorStandardIcon as f } from "@cds/core/icon/shapes/error-standard.js";
import { helpIcon as w } from "@cds/core/icon/shapes/help.js";
import $ from "./alert.element.scss.js";
function v(t) {
  const e = {
    info: [g.getIconNameFromShape(y), a.keys.alert.info],
    success: [g.getIconNameFromShape(m), a.keys.alert.success],
    warning: [g.getIconNameFromShape(b), a.keys.alert.warning],
    danger: [g.getIconNameFromShape(f), a.keys.alert.danger],
    unknown: [g.getIconNameFromShape(w), a.keys.alert.info],
    loading: ["loading", a.keys.alert.loading]
  };
  return e[t] ? e[t] : e.info;
}
function k(t) {
  return ["info", "success", "warning", "danger", "unknown"].map(t => F(t)).indexOf(t) > -1;
}
function z(t) {
  return v(t)[1];
}
function F(t) {
  return v(t)[0];
}
function S(t, e, s) {
  const a = "align:stretch";
  switch (e) {
    case "light":
      return "";
    case "banner":
      switch (t) {
        case "wrapper":
        case "actions":
          return s ? a : "";
        case "content":
          return "align:shrink align:vertical-center";
        default:
          return "";
      }
    default:
      switch (t) {
        case "wrapper":
        case "content":
          return a;
        case "actions":
          return "align:shrink";
        default:
          return "";
      }
  }
}
let j = class extends e {
  constructor() {
    super();
    this.size = "default", this.type = "light", this.closable = !1, this.status = "neutral", this.i18n = a.keys.alert, g.addIcons(y, f, m, b, w);
  }
  connectedCallback() {
    super.connectedCallback(), r(this, ["aria-describedby", this.idForAriaDescriber], ["role", "region"]);
  }
  updated(t) {
    super.updated(t), n(t, this, [this.alertActions]);
  }
  get parentGroupHasPager() {
    return "banner" === this.type && !!this.parentElement.pager;
  }
  render() {
    return s`<div class="private-host" cds-layout="${"banner" === this.type ? "horizontal wrap:none gap:xs align:vertical-center align:horizontal-center" : "horizontal wrap:none gap:xs"}">${"banner" !== this.type || this.parentGroupHasPager ? s`` : s`<span class="alert-spacer" cds-layout="align:stretch"> </span>`} <span class="alert-icon-wrapper" cds-layout="horizontal">${"loading" === this.status ? s`<cds-progress-circle class="alert-spinner" status="info" size="${"banner" === this.type ? "20" : "18"}" aria-label="${z(this.status)}" role="img" cds-layout="align:horizontal-center"></cds-progress-circle>` : s`<slot name="alert-icon"><cds-icon class="alert-status-icon" shape="${F(this.status)}" role="img" aria-label="${z(this.status)}" cds-layout="align:horizontal-center"></cds-icon></slot>`} </span><span class="alert-content-wrapper" cds-layout="horizontal wrap:none ${S("wrapper", this.type, this.parentGroupHasPager)}"><span id="${this.idForAriaDescriber}" role="status" class="alert-content" cds-layout="${S("content", this.type, this.parentGroupHasPager)}"><slot></slot>${"light" === this.type ? s`<slot name="actions"></slot>` : s``} </span>${"light" === this.type ? s`` : s`<span class="alert-actions-wrapper" cds-layout="${this.alertActions ? "p-l:md" : ""} ${S("actions", this.type, this.parentGroupHasPager)}"><slot name="actions"></slot></span>`} </span>${"banner" !== this.type || this.parentGroupHasPager ? s`` : s`<span class="alert-spacer" cds-layout="align:stretch"> </span>`} ${"light" !== this.type && this.closable ? s`<span class="alert-close-wrapper"><slot name="close-button"><cds-internal-close-button icon-size="${"banner" === this.type ? "20" : "16"}" @click="${() => this.closableController.close(!0)}" aria-label="${this.i18n.closeButtonAriaLabel}"></cds-internal-close-button></slot></span>` : s``}</div>`;
  }
  static get styles() {
    return [o, $];
  }
};
t([i({
  type: String
})], j.prototype, "size", void 0), t([c({
  type: String,
  reflect: !0
})], j.prototype, "type", void 0), t([l()], j.prototype, "idForAriaDescriber", void 0), t([i({
  type: Boolean
})], j.prototype, "closable", void 0), t([i({
  type: String
})], j.prototype, "status", void 0), t([p()], j.prototype, "i18n", void 0), t([d("cds-alert-actions")], j.prototype, "alertActions", void 0), t([h("cds-icon", {
  assign: "alert-icon"
})], j.prototype, "alertIcons", void 0), t([d("cds-internal-close-button", {
  assign: "close-button"
})], j.prototype, "closeButton", void 0), j = t([u({
  escape: !1
})], j);
export { j as CdsAlert, S as getAlertContentLayout, z as getIconStatusLabel, F as getIconStatusShape, v as getIconStatusTuple, k as iconShapeIsAlertStatusType };
