function n(n) {
  switch (n) {
    case "ascending":
      return "descending";
    case "descending":
      return "none";
    case "none":
      return "ascending";
  }
}
export { n as sortOrder };
