import { __decorate as t } from "tslib";
import { LitElement as i, html as e } from "lit";
import { I18nService as a, baseStyles as n, i18n as s, property as p, event as o } from "@cds/core/internal";
import g from "./grid-pagination.element.scss.js";
class r extends i {
  constructor() {
    super(...arguments);
    this.i18n = a.keys.grid, this.page = 0, this.pageSize = 10, this.pageCount = 1, this.pageSizeOptions = [10, 20, 50, 100];
  }
  render() {
    return e`<cds-pagination .ariaLabel="${this.i18n.pagination.label}"><cds-select control-width="shrink"><select .value="${this.pageSize.toString()}" .ariaLabel="${this.i18n.pagination.pageSize}" @input="${t => this.pageSizeChange.emit(parseInt(t.target.value))}">${this.pageSizeOptions.map(t => e`<option value="${t}" ?selected="${t === this.pageSize}">${t}</option>`)}</select></cds-select><cds-pagination-button .ariaLabel="${this.i18n.pagination.firstPage}" .disabled="${0 === this.page}" action="first" @click="${this.firstPage}"></cds-pagination-button><cds-pagination-button .ariaLabel="${this.i18n.pagination.previousPage}" .disabled="${0 === this.page}" action="prev" @click="${this.prevPage}"></cds-pagination-button><cds-input cds-pagination-number><input type="number" .ariaLabel="${`${this.i18n.pagination.page} ${this.page} of ${this.pageCount}`}" @input="${this.setPage}" .valueAsNumber="${this.page + 1}" min="1" max="${this.pageCount}"><cds-control-message><span aria-hidden="true">/ ${this.pageCount}</span></cds-control-message></cds-input><cds-pagination-button .ariaLabel="${this.i18n.pagination.nextPage}" ?disabled="${this.page === this.pageCount - 1}" action="next" @click="${this.nextPage}"></cds-pagination-button><cds-pagination-button .ariaLabel="${this.i18n.pagination.lastPage}" ?disabled="${this.page === this.pageCount - 1}" action="last" @click="${this.lastPage}"></cds-pagination-button></cds-pagination>`;
  }
  setPage(t) {
    this.pageChange.emit(t.target.valueAsNumber - 1);
  }
  nextPage() {
    this.pageChange.emit(this.page + 1);
  }
  prevPage() {
    this.pageChange.emit(this.page - 1);
  }
  firstPage() {
    this.pageChange.emit(0);
  }
  lastPage() {
    this.pageChange.emit(this.pageCount - 1);
  }
}
r.styles = [n, g], t([s()], r.prototype, "i18n", void 0), t([p({
  type: Number
})], r.prototype, "page", void 0), t([p({
  type: Number
})], r.prototype, "pageSize", void 0), t([p({
  type: Number
})], r.prototype, "pageCount", void 0), t([p({
  type: Array
})], r.prototype, "pageSizeOptions", void 0), t([o()], r.prototype, "pageChange", void 0), t([o()], r.prototype, "pageSizeChange", void 0);
export { r as CdsGridPagination };
