import { __decorate as e } from "tslib";
import { LitElement as t, html as o } from "lit";
import { baseStyles as d, property as i, event as a, querySlot as n, animate as s, AnimationAccordionPanelOpenName as r, reverseAnimation as c } from "@cds/core/internal";
import p from "./accordion-panel.element.scss.js";
let l = class extends t {
  constructor() {
    super(...arguments);
    this.cdsMotion = "on", this.disabled = !1, this.expanded = !1;
  }
  toggle() {
    this.expandedChange.emit(!this.expanded);
  }
  updated(e) {
    super.updated(e), this.content && this.header && (this.content.setAttribute("aria-labelledby", this.header.id), this.header.setAttribute("aria-controls", this.content.id), this.header.expanded = this.expanded);
  }
  render() {
    return o`<div class="private-host" role="group"><button class="accordion-header-button" cds-layout="horizontal align:vertical-center gap:sm" type="button" @click="${() => this.toggle()}" ?disabled="${this.disabled}" aria-disabled="${this.disabled}" aria-expanded="${this.expanded}" focusable><slot name="accordion-header"></slot></button><div aria-hidden="${!this.expanded}" class="accordion-content"><slot name="accordion-content"></slot></div></div>`;
  }
  static get styles() {
    return [d, p];
  }
};
e([i({
  type: String
})], l.prototype, "cdsMotion", void 0), e([a()], l.prototype, "cdsMotionChange", void 0), e([i({
  type: Boolean
})], l.prototype, "disabled", void 0), e([i({
  type: Boolean
})], l.prototype, "expanded", void 0), e([n("cds-accordion-header")], l.prototype, "header", void 0), e([n("cds-accordion-content")], l.prototype, "content", void 0), e([a()], l.prototype, "expandedChange", void 0), l = e([s({
  expanded: {
    true: r,
    false: c(r)
  }
})], l);
export { l as CdsAccordionPanel };
