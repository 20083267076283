import { __decorate as s } from "tslib";
import { LitElement as i, html as e } from "lit";
import { I18nService as t, hasStringPropertyChanged as r, updateEquilateralSizeStyles as a, isNilOrEmpty as o, baseStyles as l, property as h, i18n as n } from "@cds/core/internal";
import { getProgressCircleRadius as u } from "./progress-circle.utils.js";
import p from "./progress-circle.element.scss.js";
class d extends i {
  constructor() {
    super(...arguments);
    this.status = "neutral", this.inverse = !1, this.line = 3, this.i18n = t.keys.progress;
  }
  get radius() {
    return u(this.line);
  }
  get circumference() {
    return 2 * Math.PI * this.radius;
  }
  get progress() {
    return this.value ?? 30;
  }
  get progressOffset() {
    return (100 - this.progress) / 100 * this.circumference;
  }
  get size() {
    return this._size;
  }
  set size(s) {
    if (r(s, this._size)) {
      const i = this._size;
      this._size = s, a(this, s), this.requestUpdate("size", i);
    }
  }
  connectedCallback() {
    super.connectedCallback(), this._ariaLabel = this.ariaLabel, this.updateAria();
  }
  updated(s) {
    super.updated(s), (s.has("value") || s.has("i18n")) && this.updateAria();
  }
  updateAria() {
    this._ariaLabel === this.ariaLabel && (this._ariaLabel = o(this.value) ? this.i18n.looping : `${this.i18n.loading} ${this.value}%`, this.ariaLabel = this._ariaLabel), o(this.value) ? (this.role = "img", this.ariaValueMin = null, this.ariaValueMax = null, this.ariaValueNow = null) : (this.role = "progressbar", this.ariaValueMin = 0, this.ariaValueMax = 100, this.ariaValueNow = this.value);
  }
  render() {
    return e`<div class="private-host" aria-hidden="true"><div class="progress-wrapper"><svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false"><circle stroke-width="${this.line}" fill="none" cx="18" cy="18" r="${this.radius}" class="${this.progress > 99 ? "arcstroke" : "backstroke"}"/><path d="M 18 18 m 0,-${this.radius} a ${this.radius},${this.radius} 0 1 1 0,${2 * this.radius} a ${this.radius},${this.radius} 0 1 1 0,-${2 * this.radius}" class="fillstroke arcstroke" stroke-width="${this.line}" stroke-dasharray="${this.circumference}" stroke-dashoffset="${this.progressOffset}" fill="none"/></svg></div></div>`;
  }
  static get styles() {
    return [l, p];
  }
}
s([h({
  type: String
})], d.prototype, "status", void 0), s([h({
  type: Boolean
})], d.prototype, "inverse", void 0), s([h({
  type: Number
})], d.prototype, "value", void 0), s([h({
  type: Number
})], d.prototype, "line", void 0), s([h({
  type: String
})], d.prototype, "size", null), s([n()], d.prototype, "i18n", void 0);
export { d as CdsProgressCircle };
