import { Component, HostBinding } from '@angular/core';

import { FooterComponent } from "../../components/footer/footer.component";

@Component({
  selector: 'app-imprint',
  standalone: true,
  templateUrl: './imprint.component.html',
  styleUrl: './imprint.component.scss',
  imports: [FooterComponent]
})
export class ImprintComponent {
  @HostBinding('attr.cds-layout') cdsLayout = "vertical align:vertical-stretch wrap:none";
  @HostBinding('class') class = "route-container";
}
