import { __decorate as t } from "tslib";
import { baseStyles as e, I18nService as o, event as s, property as n } from "@cds/core/internal";
import { LitElement as p, html as i } from "lit";
import r from "./test-dropdown.element.scss.js";
class l extends p {
  constructor() {
    super(...arguments);
    this._open = !1, this.label = "dropdown";
  }
  get open() {
    return this._open;
  }
  set open(t) {
    if (t !== this._open) {
      const e = this._open;
      this._open = t, this.requestUpdate("open", e), this.openChange.emit(this.open);
    }
  }
  static get styles() {
    return [e, r];
  }
  render() {
    return i`<div class="dropdown"><button @click="${() => this.toggle()}" class="btn">${this.label}</button> ${this.open ? i`<div>${o.keys.dropdown.open}<slot></slot></div>` : ""}</div>`;
  }
  toggle() {
    this.open = !this.open;
  }
}
t([s()], l.prototype, "openChange", void 0), t([n({
  type: Boolean
})], l.prototype, "open", null), t([n({
  type: String
})], l.prototype, "label", void 0);
export { l as CdsTestDropdown };
