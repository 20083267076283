import { CdsControl as s } from "@cds/core/forms";
import t from "./textarea.element.scss.js";
class e extends s {
  constructor() {
    super(...arguments);
    this.supportsPrefixSuffixActions = !1;
  }
  static get styles() {
    return [...super.styles, t];
  }
}
export { e as CdsTextarea };
