import { __decorate as s } from "tslib";
import { html as o } from "lit";
import { I18nService as t, state as i, i18n as r } from "@cds/core/internal";
import { CdsControl as e } from "@cds/core/forms";
import { inputStyles as c } from "@cds/core/input";
import { ClarityIcons as d } from "@cds/core/icon/icon.service.js";
import { eyeIcon as a } from "@cds/core/icon/shapes/eye.js";
import { eyeHideIcon as h } from "@cds/core/icon/shapes/eye-hide.js";
class n extends e {
  constructor() {
    super();
    this.showPassword = !1, this.i18n = t.keys.password, d.addIcons(a, h);
  }
  get suffixDefaultTemplate() {
    return o`<cds-button-action @click="${() => this.togglePasswordVisibility()}" .pressed="${this.showPassword}" .ariaLabel="${this.showPassword ? this.i18n.hideButtonAriaLabel : this.i18n.showButtonAriaLabel}"><cds-icon shape="${this.showPassword ? "eye-hide" : "eye"}"></cds-icon></cds-button-action>`;
  }
  static get styles() {
    return [...super.styles, c];
  }
  togglePasswordVisibility() {
    this.showPassword = !this.showPassword, this.inputControl.type = this.showPassword ? "text" : "password", this.inputControl.focus();
  }
}
s([i()], n.prototype, "showPassword", void 0), s([r()], n.prototype, "i18n", void 0);
export { n as CdsPassword };
