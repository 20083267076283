import { __decorate as t } from "tslib";
import { LitElement as i, css as s, html as e } from "lit";
import { cache as r } from "lit/directives/cache.js";
import { baseStyles as n, notProductionEnvironment as o, LogService as a, globalStyle as l, querySlot as p } from "@cds/core/internal";
import u from "./pagination.element.scss.js";
class m extends i {
  constructor() {
    super(...arguments);
    this.globalStyles = s`[cds-pagination-number]>cds-control-message{--min-width:initial}`;
  }
  render() {
    const t = e`<slot></slot>`,
      i = e`<div cds-layout="horizontal gap:sm align:center wrap:none"><slot></slot></div>`;
    return e`${r(this.hasAttribute("cds-layout") ? t : i)}`;
  }
  static get styles() {
    return [n, u];
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.setupNumberInput(), this.validateAriaLabel();
  }
  setupNumberInput() {
    this.numberInput && (this.numberInput.controlWidth = "shrink", this.numberInput.layout = "compact");
  }
  validateAriaLabel() {
    o() && !this.getAttribute("aria-label")?.length && a.warn("An aria-label is missing", this);
  }
}
t([l()], m.prototype, "globalStyles", void 0), t([p("cds-input[cds-pagination-number]")], m.prototype, "numberInput", void 0);
export { m as CdsPagination };
