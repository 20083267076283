function t(t, r) {
  r.filter(t => t.hasAttribute("error")).forEach(t => {
    t.setAttribute("hidden", ""), t.status = "error";
  }), t.inputControl.addEventListener("blur", () => t.inputControl.checkValidity()), t.inputControl.addEventListener("invalid", () => {
    r.forEach(t => t.setAttribute("hidden", "")), r.find(r => t.inputControl.validity[r.error])?.removeAttribute("hidden"), t.status = "error";
  }), t.inputControl.addEventListener("input", () => {
    t.status = t.inputControl.validity.valid ? "neutral" : t.status, r.filter(r => t.inputControl.validity.valid && r.error && !t.inputControl.validity[r.error]).forEach(t => t.setAttribute("hidden", ""));
  });
}
export { t as syncHTML5Validation };
