import { Pipe, PipeTransform } from '@angular/core';
import { marked } from 'marked';
import type { Tokens } from 'marked';
@Pipe({
  name: 'markdownLink',
  standalone: true
})
export class MarkdownLinkPipe implements PipeTransform {
  transform(value: string | undefined, ...args: unknown[]): unknown {
    if (value && value.length > 0) {

      const renderer = new marked.Renderer();
      renderer.link = function ({ type, raw, href, title, text, tokens }: Tokens.Link) {
        const link = marked.Renderer.prototype.link.call(this, { type, raw, href, title, text, tokens });
        return link.replace("<a", "<a target='_blank' ");
      };
      marked.setOptions({
        renderer: renderer
      });
      // https://github.com/markedjs/marked/pull/1761
      return marked.parseInline(value);
    }
    return value;
  }
}
