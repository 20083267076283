class t {
  constructor(t) {
    this.host = t, this.previousPosition = "", this.host.addController(this);
  }
  get hostGrid() {
    return this.host.parentElement;
  }
  async hostUpdated() {
    await this.host.updateComplete, this.host.ariaColIndex && this.host.position !== this.previousPosition && (this.previousPosition = this.host.position, this.styles || (this.styles = document.createElement("style"), this.hostGrid.append(this.styles)), this.calculateColumnPositionStyles());
  }
  calculateColumnPositionStyles() {
    const t = this.hostGrid.getBoundingClientRect(),
      i = this.host.offsetLeft < t.width / 2 ? "left" : "right";
    this.styles.innerHTML = `${this.getPositionStyle(i, t)}\n${this.borderStyle(i)}`;
  }
  getPositionStyle(t, i) {
    const s = this.host.getBoundingClientRect(),
      o = "fixed" === this.host.position ? s.left - i.left - 1 + "px" : "initial",
      e = "fixed" === this.host.position ? s.right - s.left - s.width + "px" : "initial";
    return `\n    [__id='${this.hostGrid._id}'] [aria-colindex="${this.host.ariaColIndex}"] {\n      ${"left" === t ? `left: ${o};` : ""}\n      ${"right" === t ? `right: ${e};` : ""}\n      ${"sticky" === this.host.position ? "left: 0px;" : ""}\n    }\n\n    [__id='${this.hostGrid._id}'] cds-grid-cell[aria-colindex="${this.host.ariaColIndex}"] {\n      z-index: 98;\n    }`;
  }
  borderStyle(t) {
    const i = "left" === t && this.host.nextElementSibling.position !== this.host.position,
      s = "right" === t && this.host.previousElementSibling.position !== this.host.position;
    return "" !== this.host.position && (i || s) ? `\n      [__id='${this.hostGrid._id}'] cds-grid-cell[aria-colindex="${this.host.ariaColIndex}"] {\n        --border-${"left" === t ? "right" : "left"}: var(--cds-alias-object-border-width-100) solid var(--cds-alias-object-border-color);\n      }` : "";
  }
}
export { t as GridColumnPositionController };
