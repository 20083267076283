import { AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild } from '@angular/core';

import { Iproject } from '../../data/ukis-projects';
import Splide from '@splidejs/splide';
import { DataService } from '../../services/data.service';
import { ListSystemCardMainComponent } from "../list-system-card-main/list-system-card-main.component";

@Component({
  selector: 'app-highlight-slider',
  standalone: true,
  templateUrl: './highlight-slider.component.html',
  styleUrl: './highlight-slider.component.scss',
  imports: [ListSystemCardMainComponent]
})
export class HighlightSliderComponent implements AfterViewInit, OnDestroy {
  @Input() parentRoute!: string;
  @Input() sliderItems: Iproject[] = [];
  @ViewChild("sliderContainer") sliderElement!: ElementRef;

  slider: Splide = {} as any;
  constructor(private dataSvc: DataService) { }

  getPreviewImage = this.dataSvc.getPreviewImage;

  getRouterLink(item: Iproject) {
    return ['/systems', item.id];
  }

  ngAfterViewInit(): void {

    // configure slider
    this.slider = new Splide(this.sliderElement.nativeElement, {
      type: 'loop',
      drag: 'free',
      focus: 1,
      gap: '24px',
      interval: 3000,
      autoplay: true,
      pauseOnHover: false,
      perPage: 1,
      classes: {
        page: 'splide__pagination__page splide__page--custom'
      },
      mediaQuery: 'min',
      breakpoints: {
        768: { // sm
          perPage: 2
        },
        992: { // md
          perPage: 3,
          gap: '48px',
        },
        2560: { // xl
          perPage: 4,
          gap: '48px',
        },
        3440: { // xl
          perPage: 5,
          gap: '48px',
        }
      },
    });
    this.slider.mount();
    /* this.slider.on('move', (index)=>{
      console.log('move',1);
    }) */
  }

  ngOnDestroy(): void {
    this.slider.destroy();
  }

}
