import { __decorate as t } from "tslib";
import { html as o } from "lit";
import { CdsBaseButton as s, baseStyles as e, syncProps as a, property as r, id as i, querySlot as l } from "@cds/core/internal";
import d from "./tag.element.scss.js";
class p extends s {
  constructor() {
    super(...arguments);
    this.status = "neutral", this.closable = !1;
  }
  static get styles() {
    return [e, d];
  }
  updated(t) {
    super.updated(t), t.has("closable") && !0 === t.get("closable") && (this.readonly = !1), this.badge && a(this.badge, this, {
      status: t.has("status"),
      color: t.has("color")
    });
  }
  render() {
    return o`<div class="private-host" role="group" aria-labelledby="${this.groupLabelId}" cds-layout="horizontal align:center wrap:none"><slot name="tag-icon"></slot><span id="${this.groupLabelId}" class="tag-content" cds-text="lhe"><slot></slot></span><slot name="tag-badge"></slot>${this.closable ? o`<cds-icon shape="times"></cds-icon>` : o``}</div>`;
  }
}
t([r({
  type: String
})], p.prototype, "status", void 0), t([r({
  type: String
})], p.prototype, "color", void 0), t([r({
  type: Boolean
})], p.prototype, "closable", void 0), t([i()], p.prototype, "groupLabelId", void 0), t([l("cds-icon", {
  assign: "tag-icon"
})], p.prototype, "icon", void 0), t([l("cds-badge", {
  assign: "tag-badge"
})], p.prototype, "badge", void 0);
export { p as CdsTag };
