import { __decorate as t } from "tslib";
import { I18nService as e, getElementUpdates as o, setPopupPosition as r, setAttributes as i, isScrollable as p, baseStyles as s, i18n as n, property as d, state as a, event as c, querySlot as l, ariaPopup as h, animate as y, reverseAnimation as v, AnimationResponsivePopupEnterName as u } from "@cds/core/internal";
import { html as m } from "lit";
import { query as g } from "lit/decorators/query.js";
import { CdsInternalStaticOverlay as f } from "@cds/core/internal-components/overlay";
import b from "../overlay/shared.element.scss.js";
import S from "./popup.element.scss.js";
let A = class extends f {
  constructor() {
    super(...arguments);
    this.i18n = e.keys.popup, this.cdsMotion = "on", this.responsive = !1, this.anchorAlign = "start", this.pointerAlign = "start", this.defaultPointerType = null, this.observers = [];
  }
  get anchorElement() {
    const t = this.anchor;
    if ("string" == typeof t) {
      const e = this.getRootNode().querySelector("#" + t);
      return null === e ? this.getRootNode({
        composed: !0
      }).querySelector("#" + this.anchor) : e;
    }
    return t;
  }
  get anchorRect() {
    return this.anchorElement?.getBoundingClientRect() || new DOMRect();
  }
  setUpPositioningObserver() {
    this.observers.push(o(this, "hidden", () => {
      !1 === this.hidden && r(this);
    }));
  }
  connectedCallback() {
    if (!this.pointer && this.defaultPointerType) {
      const t = document.createElement("cds-internal-pointer");
      t.type = this.defaultPointerType, this.appendChild(t);
    }
    super.connectedCallback();
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.observers.forEach(t => t?.disconnect());
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.setUpPositioningObserver();
  }
  updated(t) {
    this.pointer && !this.pointer.hasAttribute("part") && i(this.pointer, ["part", "pointer"]), t.has("hidden") && i(this.contentWrapper, ["tabindex", !!p(this.contentWrapper) && "0"]), super.updated(t);
  }
  render() {
    return m`${this.backdropTemplate}<div class="private-host" tabindex="-1"><div cds-layout="display:screen-reader-only">${this.i18n.contentStart}</div><div class="popup-wrapper"><div class="popup-content">${this.closable || this.responsive ? this.closeButtonTemplate : ""}<slot></slot></div><div class="pointer-wrapper"><div class="popup-pointer"><slot name="pointer"></slot></div></div></div><div cds-layout="display:screen-reader-only">${this.i18n.contentEnd}</div></div>`;
  }
  static get styles() {
    return [s, S, b];
  }
};
t([n()], A.prototype, "i18n", void 0), t([d({
  type: String
})], A.prototype, "cdsMotion", void 0), t([a({
  type: Boolean,
  reflect: !0,
  attribute: "responsive"
})], A.prototype, "responsive", void 0), t([c()], A.prototype, "cdsMotionChange", void 0), t([d({
  type: Object
})], A.prototype, "trigger", void 0), t([d({
  type: String
})], A.prototype, "anchor", void 0), t([d({
  type: String
})], A.prototype, "anchorAlign", void 0), t([d({
  type: Number
})], A.prototype, "mainAxisOffset", void 0), t([d({
  type: Number
})], A.prototype, "crossAxisOffset", void 0), t([d({
  type: String
})], A.prototype, "orientation", void 0), t([d({
  type: Boolean
})], A.prototype, "closable", void 0), t([d({
  type: String
})], A.prototype, "pointerAlign", void 0), t([d({
  type: String
})], A.prototype, "defaultPointerType", void 0), t([l("cds-internal-pointer", {
  assign: "pointer"
})], A.prototype, "pointer", void 0), t([g(".popup-pointer")], A.prototype, "pointerWrapper", void 0), t([g(".private-host")], A.prototype, "hostWrapper", void 0), t([g(".popup-wrapper")], A.prototype, "popupWrapper", void 0), t([g(".popup-content")], A.prototype, "contentWrapper", void 0), A = t([h(), y({
  hidden: {
    true: v(u),
    false: u
  }
})], A);
export { A as CdsInternalPopup };
