import { __decorate as t } from "tslib";
import { LitElement as e, html as s } from "lit";
import { I18nService as i, i18n as r, property as o, state as n, querySlot as a, touch as d } from "@cds/core/internal";
import h from "./split-handle.element.scss.js";
let c = class extends e {
  constructor() {
    super(...arguments);
    this.i18n = i.keys.actions, this.direction = "vertical", this.readonly = !1, this.focused = !1, this.step = "1";
  }
  render() {
    return s`<div class="private-host"><slot></slot></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.readonly = !this.range;
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.range && (this.range.ariaLabel = this.range.ariaLabel ? this.range.ariaLabel : this.i18n.resize, this.range.addEventListener("focus", () => this.focused = !0), this.range.addEventListener("blur", () => this.focused = !1), this.addEventListener("cdsTouchStart", () => this.toughStart()), this.addEventListener("cdsTouchEnd", () => this.touchEnd()), this.addEventListener("cdsTouchMove", t => this.touchMove(t)));
  }
  toughStart() {
    this.focused = !0, this.step = this.range.step, this.range.step = "1";
  }
  touchEnd() {
    this.focused = !1, this.range.step = this.step;
  }
  touchMove(t) {
    const e = "vertical" === this.direction ? t.detail.offsetX : t.detail.offsetY;
    this.range.valueAsNumber = this.range.valueAsNumber + e, requestAnimationFrame(() => this.range.dispatchEvent(new Event("input")));
  }
};
c.styles = [h], t([r()], c.prototype, "i18n", void 0), t([o({
  type: String
})], c.prototype, "direction", void 0), t([n({
  type: Boolean,
  reflect: !0
})], c.prototype, "readonly", void 0), t([n({
  type: Boolean,
  reflect: !0
})], c.prototype, "focused", void 0), t([n()], c.prototype, "step", void 0), t([a("input")], c.prototype, "range", void 0), c = t([d()], c);
export { c as CdsInternalSplitHandle };
