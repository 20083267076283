import { __decorate as t } from "tslib";
import { LitElement as o, html as r } from "lit";
import { baseStyles as s, property as e } from "@cds/core/internal";
import i from "./control-label.element.scss.js";
class l extends o {
  constructor() {
    super(...arguments);
    this.disabled = !1, this.action = "primary";
  }
  static get styles() {
    return [s, i];
  }
  render() {
    return r`<div class="private-host" cds-layout="horizontal gap:xs align:shrink"><slot></slot></div>`;
  }
}
t([e({
  type: Boolean
})], l.prototype, "disabled", void 0), t([e({
  type: String
})], l.prototype, "action", void 0);
export { l as CdsInternalControlLabel };
