import { Component, HostBinding, HostListener, OnDestroy, OnInit } from '@angular/core';
import { Iproject } from '../../data/ukis-projects';
import { DataService, sortSystems } from '../../services/data.service';
import { DataFilterService, IrouteQuery } from './data-filter.service';
import { CollectSub } from '../../shared/collectSub';
import { ActivatedRoute } from '@angular/router';
import { first } from 'rxjs/operators';
import { FooterComponent } from '../../components/footer/footer.component';
import { ListSystemCardComponent } from '../../components/list-system-card/list-system-card.component';
import { NgSelectModule } from '@ng-select/ng-select';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CdsIconModule } from '@cds/angular/icon';
import { CommonModule, NgClass } from '@angular/common';
import { CdsButtonModule } from '@cds/angular/button';

import { ClarityIcons, filterGridCircleIcon } from "@cds/core/icon";
ClarityIcons.addIcons(...[filterGridCircleIcon]);


@Component({
  selector: 'app-list-view',
  templateUrl: './list-view.component.html',
  styleUrls: ['./list-view.component.scss'],
  standalone: true,
  imports: [CommonModule, CdsButtonModule, NgClass, CdsIconModule, FormsModule, ReactiveFormsModule, NgSelectModule, ListSystemCardComponent, FooterComponent]
})
export class ListViewComponent implements OnInit, OnDestroy {
  @HostBinding('attr.cds-layout') cdsLayout = "vertical align:vertical-stretch wrap:none";
  @HostBinding('class') class = "route-container";

  @HostListener('document:keydown', ['$event'])
  handleKeyboardEvent(event: KeyboardEvent) {
    // Check if Ctrl key is pressed along with F key
    if (event.ctrlKey && event.key === 'f') {
      // event.preventDefault(); // Prevent default browser behavior
      this.showFilterOptions = true;
    }
  }

  public systems: Iproject[] = []
  public systemsInactive: Iproject[] = []
  dates: { value: string, title: string }[] = [];
  startDate: string = 'All';

  status = [{
    value: 'active', title: 'Active'
  },
  {
    value: 'active', title: 'Inactive'
  }];
  startStatus = 'All';

  showFilterOptions = false;
  filtersActive = false;

  collectSub = new CollectSub();

  constructor(private dataSvc: DataService, public filterSvc: DataFilterService, public route: ActivatedRoute) {
    this.systems = [...this.dataSvc.getProjects('active')].sort((a, b) => sortSystems(a, b, 'random'));
    this.systemsInactive = [...this.dataSvc.getProjects('inactive')].sort((a, b) => sortSystems(a, b, 'random'));

    const dates = this.dataSvc.getDateList('startDate').map(i => ({ value: i, title: i }))
    this.filterSvc.addFilterOptions('startDate', dates);

    const tags = this.dataSvc.getTags().map(i => ({ value: i.toLocaleLowerCase(), title: i }))
    this.filterSvc.addFilterOptions('tags', tags);
  }

  ngOnInit(): void {
    this.collectSub.subs = this.filterSvc.filterForm.valueChanges.subscribe(v => {
      this.initFiltersOnRoute()
    });


    this.collectSub.subs = this.route.queryParams.pipe(first()).subscribe((p: IrouteQuery) => {
      const params = Object.keys(p).map(k => {
        const key = k as keyof IrouteQuery;
        return { key, value: p[key] }
      });

      /** get filters from route and update controls */
      if (params.length > 0) {
        params.forEach(i => {
          this.filterSvc.filterForm.controls[i.key]?.setValue(i.value);
        });
      } else {
        // filter initial on route load
        this.initFiltersOnRoute();
      }
    });
  }


  initFiltersOnRoute() {
    const activeFilters = this.filterSvc.filtersArray.map(f => (f.fc.value === 'All' || f.fc.value === '' || Array.isArray(f.fc.value) && !f.fc.value.length) ? false : true);
    this.filtersActive = activeFilters.includes(true);
    this.systems = this.filterSvc.filterSystems(this.dataSvc.getProjects('active'));
    this.systemsInactive = this.filterSvc.filterSystems(this.dataSvc.getProjects('inactive'));
  }

  ngOnDestroy(): void {
    this.collectSub.unsubscribe();
  }

  closeFilterMenu(event: Event) {
    if (this.showFilterOptions) {
      const element = event.target as HTMLElement;
      this.showFilterOptions = false;
    }
  }

  stopEvent(event: Event) {
    event.stopPropagation();
  }

  toggleFilterMenu() {
    this.showFilterOptions = !this.showFilterOptions;
  }

  toggleFilter = this.filterSvc.toggleFilter;
  filter2 = this.filterSvc.filter2;

  onTagClicked(tag: string) {
    const oldValue = this.filterSvc.filters.tags?.fc.getRawValue() as string[];
    const tagIndex = oldValue.indexOf(tag);
    if (tagIndex === -1) {
      oldValue.push(tag);
    } else {
      oldValue.splice(tagIndex, 1);
    }
    this.filterSvc.filters.tags?.fc.setValue(oldValue);
  }
}

