import { __decorate as t } from "tslib";
import { I18nService as e, baseStyles as o, property as s, state as r, i18n as l, layer as i, closable as c, focusTrap as a, ariaModal as n, firstFocus as d, triggerable as p, event as h, animate as y, reverseAnimation as u, AnimationModalEnterName as m } from "@cds/core/internal";
import { LitElement as v, html as b } from "lit";
import k from "./overlay.element.scss.js";
import g from "./shared.element.scss.js";
let f = class extends v {
  constructor() {
    super(...arguments);
    this.closable = !1, this.hidden = !1, this.demoMode = !1, this.i18n = e.keys.overlay;
  }
  static get shadowRootOptions() {
    return {
      ...super.shadowRootOptions,
      delegatesFocus: !0
    };
  }
  get closeButtonTemplate() {
    return b`<cds-internal-close-button cds-layout="align:top" aria-label="${this.i18n.closeButtonAriaLabel}" @click="${() => this.closeOverlay("close-button-click")}"></cds-internal-close-button>`;
  }
  get backdropTemplate() {
    return b`<div class="overlay-backdrop" aria-hidden="true" @click="${() => this.backdropClick()}"></div>`;
  }
  render() {
    return b`${this.backdropTemplate}<div class="private-host" tabindex="-1"><slot></slot></div>`;
  }
  backdropClick() {
    this.layerController.isActiveLayer && this.closeOverlay("backdrop-click");
  }
  closeOverlay(t = "custom") {
    this.closableController.close(t);
  }
  static get styles() {
    return [o, k, g];
  }
};
t([s({
  type: Boolean
})], f.prototype, "closable", void 0), t([s({
  type: Boolean
})], f.prototype, "hidden", void 0), t([r({
  type: Boolean,
  reflect: !0
})], f.prototype, "demoMode", void 0), t([l()], f.prototype, "i18n", void 0), f = t([i(), c(), a(), n(), d(), p()], f);
let B = class extends f {
  constructor() {
    super(...arguments);
    this.cdsMotion = "on";
  }
};
t([s({
  type: String
})], B.prototype, "cdsMotion", void 0), t([h()], B.prototype, "cdsMotionChange", void 0), B = t([y({
  hidden: {
    true: u(m),
    false: m
  }
})], B);
export { B as CdsInternalOverlay, f as CdsInternalStaticOverlay };
