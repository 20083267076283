import "@cds/core/button/register.js";
import "@cds/core/icon/register.js";
import "@cds/core/input/register.js";
import "@cds/core/select/register.js";
import { registerElementSafely as o } from "@cds/core/internal";
import { ClarityIcons as r } from "@cds/core/icon/icon.service.js";
import { angleIcon as s } from "@cds/core/icon/shapes/angle.js";
import { stepForward2Icon as e } from "@cds/core/icon/shapes/step-forward-2.js";
import { CdsPaginationButton as i } from "./pagination-button.element.js";
import { CdsPagination as t } from "./pagination.element.js";
o("cds-pagination", t), o("cds-pagination-button", i), r.addIcons(s), r.addIcons(e);
