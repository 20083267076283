import { __decorate as e } from "tslib";
import { LitElement as t, html as o } from "lit";
import { baseStyles as r, property as s } from "@cds/core/internal";
import i from "./visual-checkbox.element.scss.js";
class l extends t {
  constructor() {
    super(...arguments);
    this.disabled = !1, this.indeterminate = !1, this.selected = !1;
  }
  static get styles() {
    return [r, i];
  }
  render() {
    return o`<div class="private-host"></div>`;
  }
}
e([s({
  type: Boolean,
  reflect: !0
})], l.prototype, "disabled", void 0), e([s({
  type: Boolean,
  reflect: !0
})], l.prototype, "indeterminate", void 0), e([s({
  type: Boolean,
  reflect: !0
})], l.prototype, "selected", void 0);
export { l as CdsInternalVisualCheckbox };
