import { __decorate as t } from "tslib";
import { html as e } from "lit";
import { getElementUpdates as i, property as s, state as n, event as o } from "@cds/core/internal";
import r from "./control-inline.element.scss.js";
import { CdsControl as l, ControlLabelLayout as a } from "../control/control.element.js";
import { getStatusIcon as c } from "../utils/utils.js";
class d extends l {
  constructor() {
    super(...arguments);
    this.controlAlign = "left", this.checked = !1, this.indeterminate = !1, this.supportsPrefixSuffixActions = !1;
  }
  static get styles() {
    return [...super.styles, r];
  }
  get internalLabelTemplate() {
    return this.labelLayout !== a.ariaLabel ? e`<cds-internal-control-label action="secondary" .disabled="${this.disabled}" cds-layout="align:vertical-center"><slot name="label" @slotchange="${() => this.associateInputAndLabel()}"></slot></cds-internal-control-label>` : "";
  }
  render() {
    return e`<div class="private-host" cds-layout="${this.isControlGroup ? "horizontal align:vertical-center" : "vertical"} gap:xs"><div cds-layout="horizontal gap:xs wrap:none align:vertical-center ${"right" === this.controlAlign ? "order:reverse" : ""}"><div role="presentation" class="input" @click="${this.selectInput}"></div><div role="presentation" focusable @click="${this.selectInput}"></div>${this.internalLabelTemplate}</div>${this.messages?.length ? e`<div cds-layout="horizontal wrap:none ${this.messages?.length ? "gap:xs" : ""}">${c(this.status)}<div cds-layout="align:vertical-center" class="messages"><slot name="message"></slot></div></div>` : ""}</div><div cds-layout="display:screen-reader-only"><slot name="input"></slot></div>`;
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.inputControl.addEventListener("change", () => this.checked = this.inputControl.checked), this.observers.push(i(this.inputControl, "checked", t => this.checked = "" === t || t), i(this.inputControl, "indeterminate", t => this.indeterminate = "" === t || t));
  }
  updated(t) {
    super.updated(t), t.has("indeterminate") && t.get("indeterminate") !== this.indeterminate && this.indeterminate && (this.checked = !1), t.has("checked") && t.get("checked") !== this.checked && this.checked && (this.indeterminate = !1, this.checkedChange.emit(this.checked, {
      bubbles: !this.isControlGroup
    }));
  }
  selectInput(t) {
    this.inputControl.focus(), this.inputControl.click(), t.preventDefault();
  }
}
t([s({
  type: String
})], d.prototype, "controlAlign", void 0), t([n()], d.prototype, "isControlGroup", void 0), t([n({
  type: Boolean,
  reflect: !0
})], d.prototype, "checked", void 0), t([n({
  type: Boolean,
  reflect: !0
})], d.prototype, "indeterminate", void 0), t([o()], d.prototype, "checkedChange", void 0);
export { d as CdsInternalControlInline };
