import { __decorate as o } from "tslib";
import { LitElement as r, html as t } from "lit";
import { query as e } from "lit/decorators/query.js";
import { queryAll as i } from "lit/decorators/query-all.js";
import { I18nService as l, createId as d, baseStyles as s, i18n as p, property as c, state as a, querySlotAll as n, querySlot as y, ariaGrid as g, ariaMultiSelectable as u, keyNavigationGrid as v, gridRangeSelection as m, scrollableVisibility as h, draggableList as w } from "@cds/core/internal";
import { GridLayoutController as f } from "./grid-layout.controller.js";
import b from "./grid.element.scss.js";
let S = class extends r {
  constructor() {
    super(...arguments);
    this.i18n = l.keys.grid, this.columnLayout = "fixed", this.border = "row", this.rangeSelection = !0, this.scrollLock = !1, this._id = d(), this.gridLayoutController = new f(this), this.grid = this;
  }
  get placeholderCell() {
    return this.placeholder?.gridCell;
  }
  render() {
    return t`<div role="presentation" class="private-host"><div role="presentation" class="scroll-container"><div class="column-row-group"><div class="column-row"><slot name="columns" @slotchange="${() => this.ariaGridController.update()}"><cds-grid-column draggable-hidden><span cds-layout="display:screen-reader-only">${this.i18n.noData}</span></cds-grid-column></slot></div></div><div class="body-row-group"><slot @slotchange="${() => this.ariaGridController.update()}"><cds-grid-placeholder></cds-grid-placeholder></slot></div></div><div class="footer-row-group"><div class="footer-row"><div class="footer-grid-cell"><slot name="footer"></slot></div></div></div><slot name="detail"></slot></div>`;
  }
};
S.styles = [s, b], o([p()], S.prototype, "i18n", void 0), o([c({
  type: String
})], S.prototype, "columnLayout", void 0), o([c({
  type: String
})], S.prototype, "height", void 0), o([c({
  type: String
})], S.prototype, "border", void 0), o([c({
  type: String
})], S.prototype, "selectable", void 0), o([c({
  type: Boolean
})], S.prototype, "rangeSelection", void 0), o([c({
  type: Boolean
})], S.prototype, "scrollLock", void 0), o([a({
  type: String,
  reflect: !0
})], S.prototype, "_id", void 0), o([e(".column-row-group", !0)], S.prototype, "columnRowGroup", void 0), o([e(".column-row", !0)], S.prototype, "columnRow", void 0), o([n("cds-grid-column")], S.prototype, "columns", void 0), o([e(".body-row-group", !0)], S.prototype, "rowGroup", void 0), o([n("cds-grid-row")], S.prototype, "rows", void 0), o([n("cds-grid-cell")], S.prototype, "cells", void 0), o([e(".footer-row-group", !0)], S.prototype, "footerRowGroup", void 0), o([e(".footer-row", !0)], S.prototype, "footerRow", void 0), o([i(".footer-grid-cell")], S.prototype, "footerCells", void 0), o([y("cds-grid-placeholder")], S.prototype, "placeholder", void 0), o([e(".scroll-container", !0)], S.prototype, "keyNavGrid", void 0), S = o([g(), u(), v(), m(), h(), w({
  layout: "horizontal",
  item: "cds-grid-column",
  manageFocus: !1
}), w({
  layout: "vertical",
  item: "cds-grid-row",
  dropZone: "cds-grid-placeholder",
  manageFocus: !1
})], S);
export { S as CdsGrid };
