import { CdsInternalControlInline as t } from "@cds/core/forms";
import e from "./radio.element.scss.js";
class o extends t {
  static get styles() {
    return [...super.styles, e];
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.isControlGroup || this.associateNonGroupRadios();
  }
  associateNonGroupRadios() {
    const t = this.getRootNode();
    t.addEventListener("checkedChange", e => {
      "CDS-RADIO" === e.target.tagName && e.target.inputControl.name === this.inputControl.name && (t.querySelectorAll(`cds-radio input[type=radio][name=${this.inputControl.name}]`).forEach(t => t.checked = !1), e.target.inputControl.checked = !0);
    });
  }
}
export { o as CdsRadio };
