function e(e) {
  return {
    next: {
      shape: "angle",
      direction: "right"
    },
    last: {
      shape: "step-forward-2",
      direction: "up"
    },
    prev: {
      shape: "angle",
      direction: "left"
    },
    first: {
      shape: "step-forward-2",
      direction: "down"
    }
  }[e] ?? {
    shape: "ellipsis-vertical",
    direction: "up"
  };
}
export { e as getPaginationIconConfig };
