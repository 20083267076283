import { __decorate as t } from "tslib";
import { html as e } from "lit";
import { CdsRadio as s } from "@cds/core/radio";
import r from "../shared/selection-panel.element.scss.js";
import { property as o } from "@cds/core/internal";
class i extends s {
  constructor() {
    super(...arguments);
    this.size = "default";
  }
  static get styles() {
    return [...super.styles, r];
  }
  get internalLabelTemplate() {
    return e`<slot name="label" @slotchange="${() => this.associateInputAndLabel()}"></slot>`;
  }
}
t([o({
  type: String
})], i.prototype, "size", void 0);
export { i as CdsRadioPanel };
