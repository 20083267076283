import { __decorate as t } from "tslib";
import { LitElement as e, html as r } from "lit";
import { baseStyles as s, assignSlotNames as o, property as i, state as l } from "@cds/core/internal";
import a from "./control-message.element.scss.js";
class n extends e {
  constructor() {
    super(...arguments);
    this.status = "neutral", this.disabled = !1;
  }
  render() {
    return r`<div class="private-host"><slot></slot></div>`;
  }
  static get styles() {
    return [s, a];
  }
  connectedCallback() {
    super.connectedCallback(), o([this, "message"]);
  }
}
t([i({
  type: String
})], n.prototype, "status", void 0), t([i({
  type: String
})], n.prototype, "error", void 0), t([l({
  type: Boolean,
  reflect: !0
})], n.prototype, "disabled", void 0);
export { n as CdsControlMessage };
