import { __decorate as t } from "tslib";
import { property as o } from "@cds/core/internal";
import { state as i } from "lit/decorators/state.js";
import { query as r } from "lit/decorators/query.js";
import { CdsButtonAction as s } from "@cds/core/button-action";
import { getPaginationIconConfig as c } from "./utils.js";
class e extends s {
  updated(t) {
    super.updated(t);
    const {
      shape: o,
      direction: i
    } = c(this.action);
    this.shape = o, this.direction = i, this.cdsIcon && (this.cdsIcon.direction = this.direction);
  }
}
t([o({
  type: String
})], e.prototype, "action", void 0), t([i()], e.prototype, "direction", void 0), t([r("cds-icon")], e.prototype, "cdsIcon", void 0);
export { e as CdsPaginationButton };
