import { __decorate as t } from "tslib";
import { LitElement as e, html as r } from "lit";
import { assignSlotNames as s, baseStyles as o, state as i, querySlotAll as n } from "@cds/core/internal";
import a from "./alert-actions.element.scss.js";
class p extends e {
  constructor() {
    super(...arguments);
    this.type = "light";
  }
  render() {
    return r`<div class="private-host" cds-layout="horizontal wrap:none gap:xs align:vertical-center"><slot></slot></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), s([this, "actions"]);
  }
  updated(t) {
    super.updated(t), this.buttons.forEach(t => {
      t.status = "banner" === this.type ? "inverse" : "primary", t.size = "banner" === this.type ? "sm" : "md", t.style.setProperty("--color", "inherit");
    });
  }
  static get styles() {
    return [o, a];
  }
}
t([i({
  type: String,
  reflect: !0
})], p.prototype, "type", void 0), t([n("cds-button")], p.prototype, "buttons", void 0);
export { p as CdsAlertActions };
