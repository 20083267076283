import { __decorate as t } from "tslib";
import { LitElement as s, html as e } from "lit";
import { query as r } from "lit/decorators/query.js";
import { syncProps as a, baseStyles as i, property as o, querySlotAll as p, querySlot as l } from "@cds/core/internal";
import n from "./alert-group.element.scss.js";
class d extends s {
  constructor() {
    super(...arguments);
    this.size = "default", this.type = "default", this.status = "neutral";
  }
  render() {
    return e`<div cds-layout="horizontal wrap:none align:stretch" class="${this.pager ? "private-host" : "private-host no-pager"}"><div class="pager-wrapper"><slot name="pager"></slot></div><div class="alert-group-wrapper"><div class="alerts" cds-layout="vertical wrap:none align:horizontal-stretch fill ${"sm" === this.size ? "gap:none" : "gap:xs"}"><slot></slot></div></div></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.role = "region";
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.setupAlertsUpdate();
  }
  setupAlertsUpdate() {
    const t = {
      status: !0,
      type: !0,
      size: !0
    };
    this.alertSlot?.addEventListener("slotchange", () => this.syncAlerts(t));
  }
  async syncAlerts(t) {
    await Promise.all(Array.from(this.alerts).map(t => t.updateComplete)), this.alerts.forEach(s => a(s, this, {
      status: t.status && "light" !== this.type && "loading" !== s.status,
      type: t.type,
      size: t.size
    }));
  }
  updated(t) {
    super.updated(t), this.syncAlerts({
      status: t.has("status"),
      type: t.has("type"),
      size: t.has("size")
    });
  }
  static get styles() {
    return [i, n];
  }
}
t([o({
  type: String
})], d.prototype, "size", void 0), t([o({
  type: String
})], d.prototype, "type", void 0), t([o({
  type: String
})], d.prototype, "status", void 0), t([p("cds-alert")], d.prototype, "alerts", void 0), t([l(".pager, cds-pagination", {
  assign: "pager"
})], d.prototype, "pager", void 0), t([r(".alerts")], d.prototype, "alertSlot", void 0);
export { d as CdsAlertGroup };
