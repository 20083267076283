import { Component, HostBinding } from '@angular/core';

import { FooterComponent } from "../../components/footer/footer.component";

@Component({
  selector: 'app-terms',
  standalone: true,
  templateUrl: './terms.component.html',
  styleUrl: './terms.component.scss',
  imports: [FooterComponent]
})
export class TermsComponent {
  @HostBinding('attr.cds-layout') cdsLayout = "vertical align:vertical-stretch wrap:none";
  @HostBinding('class') class = "route-container";
}
