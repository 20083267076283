import { __decorate as t } from "tslib";
import { LitElement as e, html as o } from "lit";
import { I18nService as i, createId as s, spanWrapper as r, baseStyles as p, i18n as n, property as a, state as d, querySlot as l, querySlotAll as c } from "@cds/core/internal";
import h from "./navigation-item.element.scss.js";
import { manageScreenReaderElements as m, NAVIGATION_TEXT_WRAPPER as u } from "./utils/utils.js";
const y = "cds-navigation-item";
class v extends e {
  constructor() {
    super(...arguments);
    this.i18n = i.keys.navigation, this.active = !1, this.disabled = !1, this.expanded = !1, this.expandedGroup = !0, this.hasFocus = !1;
  }
  connectedCallback() {
    super.connectedCallback(), this.role = "listitem", this.id || (this.id = s());
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.handleItemAnchorText(), m(this, this.expanded);
  }
  handleItemAnchorText() {
    const t = this.querySelector("a");
    t && (r(t.childNodes), t?.querySelector("span")?.setAttribute(u, ""));
  }
  render() {
    return o`<div class="private-host ${this.groupItem ? "group-item" : ""}" cds-layout="horizontal align:horizontal-stretch wrap:none gap:sm"><slot @slotchange="${this.handleItemAnchorText}"></slot></div>`;
  }
  static get styles() {
    return [p, h];
  }
  updated(t) {
    super.updated(t), m(this, this.expanded);
  }
}
t([n()], v.prototype, "i18n", void 0), t([a({
  type: Boolean,
  reflect: !0
})], v.prototype, "active", void 0), t([a({
  type: Boolean,
  reflect: !0
})], v.prototype, "disabled", void 0), t([d({
  type: Boolean
})], v.prototype, "expanded", void 0), t([d({
  type: Boolean,
  reflect: !0
})], v.prototype, "expandedGroup", void 0), t([d({
  type: Boolean,
  reflect: !0
})], v.prototype, "groupItem", void 0), t([d({
  type: Boolean,
  reflect: !0
})], v.prototype, "hasFocus", void 0), t([l("a")], v.prototype, "focusElement", void 0), t([l("cds-icon", {
  assign: "cds-icon-slot"
})], v.prototype, "itemIcon", void 0), t([c("[cds-navigation-sr-text]")], v.prototype, "itemText", void 0);
export { v as CdsNavigationItem, y as CdsNavigationItemTagName };
