import { __decorate as t } from "tslib";
import { html as o } from "lit";
import { globalStyle as r } from "@cds/core/internal";
import { CdsControl as s } from "@cds/core/forms";
import { inputStyles as e } from "@cds/core/input";
import l from "./time.global.scss.js";
class c extends s {
  constructor() {
    super(...arguments);
    this.globalStyles = l;
  }
  get suffixDefaultTemplate() {
    return o`<cds-button-action shape="clock" readonly="readonly"></cds-button-action>`;
  }
  static get styles() {
    return [...super.styles, e];
  }
}
t([r()], c.prototype, "globalStyles", void 0);
export { c as CdsTime };
