import { __decorate as t } from "tslib";
import { html as s } from "lit";
import { getElementUpdates as r, globalStyle as i } from "@cds/core/internal";
import { CdsControl as e } from "@cds/core/forms";
import o from "./range.global.scss.js";
import n from "./range.element.scss.js";
class p extends e {
  constructor() {
    super(...arguments);
    this.globalStyles = o;
  }
  static get styles() {
    return [...super.styles, n];
  }
  get inputTemplate() {
    return s`<div class="input-track"></div>`;
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.setTrackWidth(), this.inputControl.addEventListener("input", () => this.setTrackWidth()), this.observers.push(r(this.inputControl, "value", t => {
      this.setTrackWidth(t);
    }));
  }
  setTrackWidth(t) {
    const s = t ?? this.inputControl.valueAsNumber,
      r = this.inputControl.min ? parseInt(this.inputControl.min) : 0,
      i = this.inputControl.max ? parseInt(this.inputControl.max) : 100;
    this.style.setProperty("--track-width", Math.floor((s - r) / (i - r) * 100) + "%");
  }
}
t([i()], p.prototype, "globalStyles", void 0);
export { p as CdsRange };
