import { __decorate as e } from "tslib";
import { onAnyKey as t, onKey as i, nextInArray as n, previousInArray as s, arrayHead as r, arrayTail as a, syncProps as c, isVisible as d, baseStyles as l, property as h, querySlot as o, querySlotAll as m } from "@cds/core/internal";
import { LitElement as p, html as v } from "lit";
import { CdsTreeItem as u } from "./tree-item.element.js";
import A from "./tree.element.scss.js";
class f extends p {
  constructor() {
    super(...arguments);
    this.multiSelect = !1, this.keyboardNavigationHandler = e => {
      const c = this.currentActiveItem;
      c && c instanceof u && (t(["arrow-left", "arrow-right", "arrow-up", "arrow-down", "home", "end", "enter", "space"], e, () => {
        e.preventDefault();
      }), i("arrow-left", e, () => {
        if (c.expandable && c.expanded) c.expandedChange.emit(!1);else {
          const e = c.parentElement;
          e && e instanceof u && this.setAriaActiveDescendant(e);
        }
      }), i("arrow-right", e, () => {
        c.expandable && (c.expanded ? this.setAriaActiveDescendant(n(c, this.visibleChildren)) : c.expandedChange.emit(!0));
      }), i("arrow-down", e, () => {
        c && this.setAriaActiveDescendant(n(c, this.visibleChildren));
      }), i("arrow-up", e, () => {
        c && this.setAriaActiveDescendant(s(c, this.visibleChildren));
      }), i("home", e, () => {
        c && this.setAriaActiveDescendant(r(this.visibleChildren));
      }), i("end", e, () => {
        c && this.setAriaActiveDescendant(a(this.visibleChildren));
      }), i("enter", e, () => {
        c.anchorLink && c.anchorLink.click(), c.expandable && c.expandedChange.emit(!c.expanded);
      }), i("space", e, () => {
        c.anchorLink && c.anchorLink.click(), c && !c.disabled && c.selectedChange.emit(!c.selected);
      }));
    };
  }
  connectedCallback() {
    super.connectedCallback(), this.tabIndex = 0, this.role = "tree", this.addEventListener("focus", this.initAriaActiveDescendant), this.addEventListener("click", this.clickHandler), this.addEventListener("keydown", this.keyboardNavigationHandler);
  }
  disconnectedCallback() {
    super.disconnectedCallback();
  }
  updated(e) {
    super.updated(e), this.ariaMultiSelectable = this.multiSelect ? "true" : "false", this.childrenItems.forEach(e => c(e, this, {
      multiSelect: this.multiSelect
    }));
  }
  clickHandler(e) {
    if (e.target instanceof u) {
      const t = this.visibleChildren.find(t => t === e.target);
      t && this.setAriaActiveDescendant(t);
    }
  }
  get currentActiveItem() {
    return this.visibleChildren.find(e => e.id === this.ariaActiveDescendant);
  }
  initAriaActiveDescendant() {
    if (!this.currentActiveItem) {
      const e = this.visibleChildren.find(e => e.selected) || this.firstChildItem;
      e && this.setAriaActiveDescendant(e);
    }
  }
  setAriaActiveDescendant(e) {
    e && (this.ariaActiveDescendant = e.id, e.focus());
  }
  get visibleChildren() {
    return Array.from(this.childrenItems).filter(e => d(e));
  }
  render() {
    return v`<slot></slot>`;
  }
  static get styles() {
    return [l, A];
  }
}
e([h({
  type: Boolean,
  attribute: "multi-select"
})], f.prototype, "multiSelect", void 0), e([o("cds-tree-item")], f.prototype, "firstChildItem", void 0), e([m("cds-tree-item")], f.prototype, "childrenItems", void 0);
export { f as CdsTree };
