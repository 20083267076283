import { __decorate as t } from "tslib";
import { html as r } from "lit";
import { globalStyle as o, querySlot as e } from "@cds/core/internal";
import { CdsControl as s } from "@cds/core/forms";
import { inputStyles as l } from "@cds/core/input";
import a from "./datalist.global.scss.js";
class i extends s {
  constructor() {
    super(...arguments);
    this.globalStyles = a;
  }
  get suffixDefaultTemplate() {
    return r`<cds-button-expand expanded readonly="readonly"></cds-button-expand>`;
  }
  static get styles() {
    return [...super.styles, l];
  }
}
t([o()], i.prototype, "globalStyles", void 0), t([e("datalist", {
  required: "error"
})], i.prototype, "datalistControl", void 0);
export { i as CdsDatalist };
