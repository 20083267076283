import { __decorate as e } from "tslib";
import { I18nService as t, createId as i, baseStyles as d, i18n as s, property as o, event as a, querySlotAll as l, querySlot as n, animate as r, AnimationTreeItemExpandName as c, reverseAnimation as p } from "@cds/core/internal";
import { LitElement as h, html as m } from "lit";
import { ifDefined as g } from "lit/directives/if-defined.js";
import v from "./tree-item.element.scss.js";
let x = class extends h {
  constructor() {
    super(...arguments);
    this.i18n = t.keys.treeview, this.cdsMotion = "on", this.multiSelect = !1, this.disabled = !1, this.expanded = !1, this.expandable = !1, this.indeterminate = !1, this.loading = !1, this.selected = !1;
  }
  connectedCallback() {
    super.connectedCallback(), this.tabIndex = -1, this.role = "treeitem", this.id || (this.id = i());
  }
  updated(e) {
    super.updated(e), this.treeItemChildren.length > 0 && (this.expandable = !0), this.expandable ? this.ariaExpanded = this.expanded ? "true" : "false" : this.ariaExpanded = null, this.multiSelect ? this.ariaSelected = this.selected ? "true" : "false" : this.ariaSelected = null, this.ariaDisabled = this.disabled ? "true" : "false";
  }
  toggleExpanded() {
    this.expandedChange.emit(!this.expanded);
  }
  toggleSelected() {
    this.selectedChange.emit(!this.selected), this.anchorLink && this.anchorLink.click();
  }
  onSlotChange() {
    this.treeItemChildren.forEach(e => {
      e.getAttribute("slot") || e.setAttribute("slot", i());
    }), this.requestUpdate();
  }
  render() {
    return m`<div class="private-host" cds-layout="p-l:md"><div cds-layout="horizontal align:vertical-center ${this.multiSelect ? "gap:xs" : "gap:xxs"}"><div class="lhs-container">${this.treeItemLeftHandSideTemplate}</div><div class="item-content" cds-layout="horizontal p:xxs align:vertical-center align:stretch" @click="${() => this.toggleSelected()}"><span cds-layout="horizontal align:vertical-center gap:sm" cds-text="lhe">${this.multiSelect ? m`<cds-internal-visual-checkbox part="checkbox" .disabled="${this.disabled}" .indeterminate="${this.indeterminate}" .selected="${this.selected}"></cds-internal-visual-checkbox>` : m``}<slot @slotchange="${this.onSlotChange}"></slot></span></div></div><div class="item-children" role="${g(this.expanded ? "group" : void 0)}" ?hidden="${!this.expanded}">${Array.from(this.treeItemChildren).map(e => m`<slot name="${e.getAttribute("slot")}"></slot>`)}</div></div>`;
  }
  get treeItemLeftHandSideTemplate() {
    return this.loading ? m`<cds-progress-circle size="xs" aria-label="${this.i18n.loading}"></cds-progress-circle>` : this.expandable ? m`<div @click="${() => this.toggleExpanded()}"><cds-button-expand .expanded="${this.expanded}" readonly="readonly" action="vertical" part="expand-collapse-icon"></cds-button-expand></div>` : m``;
  }
  static get styles() {
    return [d, v];
  }
};
e([s()], x.prototype, "i18n", void 0), e([o({
  type: String
})], x.prototype, "cdsMotion", void 0), e([a()], x.prototype, "cdsMotionChange", void 0), e([o({
  type: Boolean,
  reflect: !0,
  attribute: "multi-select"
})], x.prototype, "multiSelect", void 0), e([o({
  type: Boolean
})], x.prototype, "disabled", void 0), e([o({
  type: Boolean,
  reflect: !0
})], x.prototype, "expanded", void 0), e([o({
  type: Boolean,
  reflect: !0
})], x.prototype, "expandable", void 0), e([o({
  type: Boolean,
  reflect: !0
})], x.prototype, "indeterminate", void 0), e([o({
  type: Boolean,
  reflect: !0
})], x.prototype, "loading", void 0), e([o({
  type: Boolean,
  reflect: !0
})], x.prototype, "selected", void 0), e([l(":scope > cds-tree-item")], x.prototype, "treeItemChildren", void 0), e([n(":scope > a")], x.prototype, "anchorLink", void 0), e([a()], x.prototype, "expandedChange", void 0), e([a()], x.prototype, "selectedChange", void 0), x = e([r({
  expanded: {
    true: c,
    false: p(c)
  }
})], x);
export { x as CdsTreeItem };
