import { Component, HostBinding } from '@angular/core';
import { logo } from '../../data/about.page';
import { FooterComponent } from '../../components/footer/footer.component';
import { NgOptimizedImage } from '@angular/common';
import { KpiSectionComponent } from '../../components/kpi-section/kpi-section.component';


@Component({
  selector: 'app-about',
  templateUrl: './about.component.html',
  styleUrls: ['./about.component.scss'],
  standalone: true,
  imports: [KpiSectionComponent, NgOptimizedImage, FooterComponent]
})
export class AboutComponent {
  @HostBinding('attr.cds-layout') cdsLayout = "vertical align:vertical-stretch wrap:none";
  @HostBinding('class') class = "route-container";

  public logo = logo;
}
