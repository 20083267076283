import { __decorate as t } from "tslib";
import { LitElement as e, html as i } from "lit";
import { I18nService as a, baseStyles as n, setAttributes as o, syncPropsForAllItems as s, syncProps as r, isVisible as d, onKey as c, property as h, event as v, state as l, i18n as g, querySlot as p, querySlotAll as m, animate as u, reverseAnimation as f } from "@cds/core/internal";
import I from "./navigation.element.scss.js";
import { removeFocus as y, setFocus as A, visibleElement as b, getNextFocusElement as S, getPreviousFocusElement as E } from "./utils/utils.js";
import { AnimationNavigationOpenName as x } from "../internal/motion/animations/cds-navigation-open.js";
const N = "cds-navigation";
let C = class extends e {
  constructor() {
    super(...arguments);
    this.expandedRoot = !1, this.cdsMotion = "on", this.groupItem = !0, this.expanded = !1, this.i18n = a.keys.navigation;
  }
  static get styles() {
    return [n, I];
  }
  get endTemplate() {
    return this.navigationEnd ? i`<div class="navigation-end" cds-layout="vertical align:horizontal-stretch"><slot name="cds-navigation-end" @slotchange="${this.onItemSlotChange}"></slot></div>` : "";
  }
  get startTemplate() {
    let t;
    return t = this.rootNavigationStart ? i`<div class="navigation-start" cds-layout="vertical align:horizontal-stretch"><slot @slotchange="${() => this.onStartItemSlotChange()}" name="navigation-start"></slot><cds-divider class="start-divider"></cds-divider></div>` : "", t;
  }
  render() {
    return i`<div class="private-host" aria-label="${this.i18n.navigationLabel}" cds-layout="vertical wrap:none">${this.startTemplate}<slot name="cds-navigation-substart"></slot><nav class="navigation-body-wrapper"><div .ariaActiveDescendant="${this.ariaActiveDescendant}" tabindex="0" id="item-container"><div class="navigation-body" cds-layout="vertical wrap:none align:horizontal-stretch"><slot @slotchange="${this.onItemSlotChange}"></slot></div></div></nav>${this.endTemplate}</div>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.role = "list", this.rootNavigationStart?.addEventListener("focus", this.focusRootStart.bind(this)), this.rootNavigationStart?.addEventListener("blur", this.blurRootStart.bind(this)), this.rootNavigationStart?.addEventListener("keydown", this.handleRootStartKeys.bind(this));
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.rootNavigationStart && this.rootNavigationStart.removeEventListener("click", this.toggle.bind(this));
  }
  firstUpdated(t) {
    super.firstUpdated(t);
    const e = this.shadowRoot?.querySelector("#item-container");
    e?.addEventListener("focus", this.initItemKeys.bind(this)), e?.addEventListener("keydown", this.handleItemKeys.bind(this)), e?.addEventListener("blur", this.blurItemKeys.bind(this));
  }
  updated(t) {
    super.updated(t), t.has("expanded") && (this.expandedRoot = this.expanded), this.updateChildrenProps();
  }
  onStartItemSlotChange() {
    this.onItemSlotChange(), this.rootNavigationStart && this.rootNavigationStart.addEventListener("click", this.toggle.bind(this));
  }
  onItemSlotChange() {
    this.updateChildrenProps(), this.allNavigationElements.forEach(t => {
      o(t, ["tabindex", "-1"]);
    });
  }
  updateChildrenProps() {
    this.navigationGroupItems && s(Array.from(this.navigationGroupItems), this, {
      groupItem: !0
    }), this.navigationItemRefs && s(Array.from(this.navigationItemRefs), this, {
      expanded: !0
    }), this.navigationStartRefs && s(Array.from(this.navigationStartRefs), this, {
      expandedRoot: !0
    }), this.rootNavigationStart && r(this.rootNavigationStart, this, {
      expanded: this.expanded
    }), this.rootNavigationItems.length > 0 && s(Array.from(this.rootNavigationItems), this, {
      expanded: this.expanded
    }), this.navigationGroupRefs.length > 0 && s(Array.from(this.navigationGroupRefs), this, {
      layout: !0
    });
  }
  get visibleChildren() {
    return Array.from(this.allNavigationElements).filter(t => d(t));
  }
  toggle() {
    this.expandedChange.emit(!this.expanded);
  }
  get currentActiveItem() {
    return this.visibleChildren.find(t => t.id === this.ariaActiveDescendant);
  }
  blurItemKeys() {
    this.currentActiveItem && y(this.currentActiveItem);
  }
  focusRootStart() {
    this.rootNavigationStart && A(this.rootNavigationStart);
  }
  blurRootStart() {
    this.rootNavigationStart && y(this.rootNavigationStart);
  }
  initItemKeys() {
    if (this.currentActiveItem) {
      const t = this.currentActiveItem ? this.currentActiveItem : this.allNavigationElements[0];
      A(t), this.ariaActiveDescendant = t.id;
    } else A(this.focusableElements[0]), this.ariaActiveDescendant = this.focusableElements[0].id;
  }
  get focusableElements() {
    return Array.from(this.allNavigationElements).filter(b);
  }
  handleItemKeys(t) {
    c("arrow-down", t, () => {
      if (this.currentActiveItem) {
        y(this.currentActiveItem);
        const e = S(this.currentActiveItem, this.focusableElements);
        this.ariaActiveDescendant = e.id, A(e), t.preventDefault();
      }
    }), c("arrow-up", t, () => {
      if (this.currentActiveItem) {
        y(this.currentActiveItem);
        const e = E(this.currentActiveItem, this.focusableElements);
        this.ariaActiveDescendant = e.id, A(e), t.preventDefault();
      }
    }), c("arrow-right", t, () => {
      const t = this.currentActiveItem?.closest("cds-navigation-group");
      !t || t.expanded || t.expandedChange.emit(!t.expanded);
    }), c("arrow-left", t, () => {
      const t = this.currentActiveItem?.closest("cds-navigation-group");
      if ("CDS-NAVIGATION-ITEM" === this.currentActiveItem?.tagName && t) {
        const e = t?.querySelector("cds-navigation-start");
        return y(this.currentActiveItem), this.ariaActiveDescendant = e?.id ?? null, void A(e);
      }
      t && t.expanded && t.expandedChange.emit(!t.expanded);
    }), c("home", t, () => {
      if (this.currentActiveItem) {
        y(this.currentActiveItem);
        const t = this.focusableElements[0];
        this.ariaActiveDescendant = t.id, A(t);
      }
    }), c("end", t, () => {
      if (this.currentActiveItem) {
        y(this.currentActiveItem);
        const t = this.focusableElements[this.focusableElements.length - 1];
        this.ariaActiveDescendant = t.id, A(t);
      }
    }), c("enter", t, () => {
      this.currentActiveItem?.focusElement && this.currentActiveItem?.focusElement.click();
    }), c("space", t, () => {
      this.currentActiveItem?.focusElement && this.currentActiveItem?.focusElement.click();
    });
  }
  handleRootStartKeys(t) {
    c("arrow-right", t, () => {
      this.expanded || this.toggle();
    }), c("arrow-left", t, () => {
      this.expanded && this.toggle();
    });
  }
};
t([h({
  type: String
})], C.prototype, "cdsMotion", void 0), t([v()], C.prototype, "expandedChange", void 0), t([v()], C.prototype, "cdsMotionChange", void 0), t([l({
  type: Boolean
})], C.prototype, "groupItem", void 0), t([h({
  type: Boolean
})], C.prototype, "expanded", void 0), t([g()], C.prototype, "i18n", void 0), t([p('[slot="cds-navigation-end"]', {
  assign: "cds-navigation-end"
})], C.prototype, "navigationEnd", void 0), t([m("cds-navigation-group > cds-navigation-start, cds-navigation-item:not([disabled])")], C.prototype, "allNavigationElements", void 0), t([m("cds-navigation-start")], C.prototype, "navigationStartRefs", void 0), t([m(":scope > cds-divider")], C.prototype, "rootDividers", void 0), t([m(":scope > cds-navigation-group")], C.prototype, "rootNavigationGroups", void 0), t([m(":scope > cds-navigation-item")], C.prototype, "rootNavigationItems", void 0), t([p(":scope > cds-navigation-start", {
  assign: "navigation-start"
})], C.prototype, "rootNavigationStart", void 0), t([m(":scope > cds-navigation-group > cds-navigation-item")], C.prototype, "navigationGroupItems", void 0), t([m("cds-navigation-item")], C.prototype, "navigationItemRefs", void 0), t([m("cds-navigation-group")], C.prototype, "navigationGroupRefs", void 0), C = t([u({
  expanded: {
    true: x,
    false: f(x)
  }
})], C);
export { C as CdsNavigation, N as CdsNavigationTagName };
