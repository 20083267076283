import { __decorate as e } from "tslib";
import { LitElement as r, html as t } from "lit";
import { query as o } from "lit/decorators/query.js";
import { I18nService as s, baseStyles as i, i18n as l } from "@cds/core/internal";
import c from "./grid-placeholder.element.scss.js";
class d extends r {
  constructor() {
    super(...arguments);
    this.i18n = s.keys.grid;
  }
  connectedCallback() {
    super.connectedCallback(), this.role = "row";
  }
  render() {
    return t`<div role="gridcell" class="private-host" cds-layout="vertical gap:md align:center"><slot>${"false" !== this.getAttribute("draggable") ? t`<cds-icon shape="filter" size="xl"></cds-icon><p cds-text="message">${this.i18n.noData}</p>` : t`<p cds-layout="display:screen-reader-only">${this.i18n.dropTarget}</p>`}</slot></div>`;
  }
}
d.styles = [i, c], e([l()], d.prototype, "i18n", void 0), e([o('[role="gridcell"]', !0)], d.prototype, "gridCell", void 0);
export { d as CdsGridPlaceholder };
