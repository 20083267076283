import { __decorate as t } from "tslib";
import { html as s } from "lit";
import { CdsBaseButton as e, getElementWidth as i, baseStyles as a, property as o } from "@cds/core/internal";
import r from "./button.element.scss.js";
var d;
!function (t) {
  t.default = "default", t.loading = "loading", t.success = "success", t.error = "error";
}(d || (d = {}));
class l extends e {
  constructor() {
    super(...arguments);
    this.action = "solid", this.status = "primary", this.size = "md", this.block = !1, this.loadingState = d.default, this._disabledExternally = !1;
  }
  get disabled() {
    return super.disabled;
  }
  set disabled(t) {
    this._disabledExternally = t, super.disabled = t;
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.isDefaultLoadingState(this.loadingState) || (super.disabled = !0);
  }
  update(t) {
    t.has("loadingState") && void 0 !== t.get("loadingState") && (this.isDefaultLoadingState(this.loadingState) ? this.restoreButton() : this.disableButton()), super.update(t);
  }
  render() {
    return s`<div class="private-host" cds-layout="horizontal gap:xs wrap:none align:center">${this.loadingState === d.success ? s`<cds-icon shape="check" status="success" size="18"></cds-icon>` : ""} ${this.loadingState === d.error ? s`<cds-icon shape="error-standard" status="danger" size="18"></cds-icon>` : ""} ${this.loadingState === d.loading ? s`<cds-progress-circle .size="${"sm" === this.size ? "12" : "18"}" status="info"></cds-progress-circle>` : ""} ${this.loadingState === d.default ? s`<slot></slot>` : ""}</div>`;
  }
  isDefaultLoadingState(t) {
    return t === d.default;
  }
  disableButton() {
    this.style.width = i(this), super.disabled = !0;
  }
  restoreButton() {
    this.style.removeProperty("width"), super.disabled = this._disabledExternally;
  }
}
l.styles = [a, r], t([o({
  type: String
})], l.prototype, "action", void 0), t([o({
  type: String
})], l.prototype, "status", void 0), t([o({
  type: String
})], l.prototype, "size", void 0), t([o({
  type: Boolean
})], l.prototype, "block", void 0), t([o({
  type: String
})], l.prototype, "loadingState", void 0), t([o({
  type: Boolean
})], l.prototype, "disabled", null);
export { l as CdsButton, d as ClrLoadingState };
