import { __decorate as t } from "tslib";
import { querySlotAll as o, id as r } from "@cds/core/internal";
import { CdsInternalControlGroup as s } from "@cds/core/forms";
class e extends s {
  static get styles() {
    return [...super.styles];
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.associateRadioControls(), this.syncRadioControls();
  }
  associateRadioControls() {
    this.controls.forEach(t => {
      t && !t.inputControl.getAttribute("name") && t.inputControl.setAttribute("name", this.radioName);
    });
  }
  syncRadioControls() {
    this.controls.forEach(t => t.addEventListener("checkedChange", t => {
      t.detail && (Array.from(this.controls).forEach(t => t.inputControl.checked = !1), t.target.inputControl.checked = !0);
    }));
  }
}
t([o("cds-radio, cds-radio-panel")], e.prototype, "controls", void 0), t([r()], e.prototype, "radioName", void 0);
export { e as CdsRadioGroup };
