import { __decorate as t } from "tslib";
import { baseStyles as r, property as s } from "@cds/core/internal";
import { LitElement as o, html as e } from "lit";
import i from "./badge.element.scss.js";
class l extends o {
  constructor() {
    super(...arguments);
    this.color = null, this.status = "neutral";
  }
  render() {
    return e`<div class="private-host"><span cds-text="lhe"><slot></slot></span></div>`;
  }
  static get styles() {
    return [r, i];
  }
}
t([s({
  type: String
})], l.prototype, "color", void 0), t([s({
  type: String
})], l.prototype, "status", void 0);
export { l as CdsBadge };
