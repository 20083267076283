import { html as t } from "lit";
import { createId as n, pxToRem as i } from "@cds/core/internal";
const e = ["vertical", "vertical-inline", "horizontal", "horizontal-inline", "compact"],
  o = "horizontal",
  r = "stretch";
function c(t, n) {
  t && n && (l(t), n.setAttribute("for", t.id));
}
function a(t, n) {
  n && (l(t), n.id = t.id + "-datalist", t.setAttribute("list", n.id));
}
function l(t) {
  t.id.length || (t.id = n());
}
const s = {
  "horizontal-inline": "horizontal",
  "vertical-inline": "vertical",
  horizontal: "horizontal",
  vertical: "vertical",
  compact: "compact"
};
function u(n) {
  return t`${"neutral" !== n ? t`<cds-button-action readonly="readonly" class="status" cds-layout="align:shrink"><cds-icon status="${"error" === n ? "danger" : "success"}" shape="${"error" === n ? "exclamation-circle" : "check-circle"}" size="16" inner-offset="${4}"></cds-icon></cds-button-action>` : ""}`;
}
async function d(t) {
  return Promise.all(t.map(t => t.updateComplete)).then(() => {
    const n = t.filter(t => "primary" === t.controlLabel?.action);
    return i(Math.max(...n.map(t => t.controlLabel.getBoundingClientRect().width)));
  });
}
function h(t, n, i) {
  return "vertical" !== i && "vertical-inline" !== i && t.getBoundingClientRect().top > n?.getBoundingClientRect().top + 12;
}
function p(t, n) {
  const i = t[0],
    e = t[t.length - 1];
  return ("vertical-inline" === n || "horizontal-inline" === n) && e.getBoundingClientRect().top > i.getBoundingClientRect().top;
}
function f(t) {
  return "vertical" === t || "vertical-inline" === t;
}
async function m(t) {
  const n = t.find(t => "neutral" !== t.status);
  return await n?.updateComplete, n && !n.hidden ? n.status : "neutral";
}
export { l as assignInputId, c as associateInputAndLabel, a as associateInputToDatalist, h as controlIsWrapped, r as defaultControlWidth, o as defaultFormLayout, e as formLayouts, m as getCurrentMessageStatus, d as getLargestPrimaryLabelWidth, u as getStatusIcon, p as inlineControlListIsWrapped, f as isVerticalLayout, s as layoutGroupToControlMapper };
