import { __decorate as t } from "tslib";
import { LitElement as e, html as i } from "lit";
import { I18nService as o, ResponsiveController as r, renderAfter as s, listenForAttributeChange as n, propUpdated as l, baseStyles as h, property as a, i18n as d, state as c, layer as g, closable as p, ariaModal as u, ariaPopup as y, focusTrap as m, firstFocus as v, triggerable as w } from "@cds/core/internal";
import b from "./grid-detail.element.scss.js";
let C = class extends e {
  constructor() {
    super(...arguments);
    this.position = "right", this.i18n = o.keys.grid, this.overlay = "", this.responsiveController = new r(this, {
      element: this.parentElement
    });
  }
  get grid() {
    return this.parentElement;
  }
  get trigger() {
    return "string" == typeof this.anchor ? this.getRootNode().querySelector("#" + this.anchor) : this.anchor;
  }
  get closeButton() {
    return i`<cds-button-action @click="${() => this.closableController.close()}" shape="times" aria-label="${this.i18n.closeDetails}" part="close"></cds-button-action>`;
  }
  get triggerRow() {
    return this.trigger?.closest("cds-grid-row");
  }
  render() {
    return i`<div class="private-host" role="presentation"><div cds-layout="display:screen-reader-only">${this.i18n.rowDetailStart}</div><slot></slot><div cds-layout="display:screen-reader-only">${this.i18n.rowDetailEnd}</div></div><div class="caret" role="presentation"></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.slot = "detail", s(this.closeButton, this), this.addEventListener("cdsResizeChange", t => this.overlay = t.detail.width > 500 ? "" : "full"), this.observer = n(this, "hidden", async t => {
      this.grid.scrollLock = !t, await this.updateComplete, this.setDetailWidthAlignment(), this.toggleAnchorHover();
    });
  }
  async updated(t) {
    super.updated(t), await this.updateComplete, l(this, t, "anchor") && this.setAnchorPointer(t.get("anchor"));
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.observer?.disconnect(), this.triggerRow?.removeAttribute("_detail-row");
  }
  toggleAnchorHover() {
    this.hidden ? this.triggerRow?.removeAttribute("_detail-row") : this.triggerRow?.setAttribute("_detail-row", "");
  }
  setAnchorPointer(t) {
    t?.closest && t?.closest("cds-grid-row")?.removeAttribute("_detail-row"), this.toggleAnchorHover();
    const e = this.trigger?.getBoundingClientRect()?.top - this?.getBoundingClientRect().top - 8;
    this.style.setProperty("--caret-top", e + "px");
  }
  setDetailWidthAlignment() {
    "bottom" === this.position && this.alignToBottom();
    const t = Array.from(this.triggerRow?.cells ?? []).find(t => "rowheader" === t.role);
    t && this.alignToSide(t);
  }
  alignToBottom() {
    const t = this.parentElement?.getBoundingClientRect(),
      e = this.triggerRow?.getBoundingClientRect();
    this.style.setProperty("--width", "100%"), "bottom" === this.position && e && (this.style.top = e?.bottom - t.top - 1 + "px");
  }
  alignToSide(t) {
    const e = this.parentElement?.getBoundingClientRect(),
      i = t.getBoundingClientRect();
    this.style.setProperty("--width", "auto"), "left" === this.position || "rtl" === this.parentElement?.getAttribute("dir") ? this.style.right = e.right - i.left + "px" : this.style.left = i.right - e.left + "px";
  }
};
C.styles = [h, b], t([a({
  type: String
})], C.prototype, "anchor", void 0), t([a({
  type: String
})], C.prototype, "position", void 0), t([d()], C.prototype, "i18n", void 0), t([c({
  type: String,
  reflect: !0
})], C.prototype, "overlay", void 0), C = t([g(), p(), u(), y(), m(), v(), w()], C);
export { C as CdsGridDetail };
