import { __decorate as o } from "tslib";
import { LitElement as t, html as r } from "lit";
import { baseStyles as e, property as i, querySlotAll as s } from "@cds/core/internal";
import { GridRowPositionController as l } from "./grid-row-position.controller.js";
import p from "./grid-row.element.scss.js";
class n extends t {
  constructor() {
    super(...arguments);
    this.gridRowPositionController = new l(this);
  }
  render() {
    return r`<div class="private-host"><slot></slot></div>`;
  }
}
n.styles = [e, p], o([i({
  type: Boolean
})], n.prototype, "selected", void 0), o([i({
  type: String
})], n.prototype, "position", void 0), o([s("cds-grid-cell")], n.prototype, "cells", void 0);
export { n as CdsGridRow };
