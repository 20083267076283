import { __decorate as e } from "tslib";
import { html as t } from "lit";
import { CdsCheckbox as s } from "@cds/core/checkbox";
import r from "../shared/selection-panel.element.scss.js";
import { property as o } from "@cds/core/internal";
class l extends s {
  constructor() {
    super(...arguments);
    this.size = "default";
  }
  get internalLabelTemplate() {
    return t`<slot name="label" @slotchange="${() => this.associateInputAndLabel()}"></slot>`;
  }
  static get styles() {
    return [...super.styles, r];
  }
}
e([o({
  type: String
})], l.prototype, "size", void 0);
export { l as CdsCheckboxPanel };
