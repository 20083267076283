import "@cds/core/icon/register.js";
import "@cds/core/button-action/register.js";
import { ClarityIcons as o } from "@cds/core/icon/icon.service.js";
import { exclamationCircleIcon as r } from "@cds/core/icon/shapes/exclamation-circle.js";
import { checkCircleIcon as c } from "@cds/core/icon/shapes/check-circle.js";
import { registerElementSafely as e } from "@cds/core/internal";
import { CdsControl as n } from "./control/control.element.js";
import { CdsControlAction as t } from "./control-action/control-action.element.js";
import { CdsInternalControlGroup as l } from "./control-group/control-group.element.js";
import { CdsInternalControlInline as s } from "./control-inline/control-inline.element.js";
import { CdsInternalControlLabel as i } from "./control-label/control-label.element.js";
import { CdsControlMessage as m } from "./control-message/control-message.element.js";
import { CdsFormGroup as p } from "./form-group/form-group.element.js";
e("cds-control", n), e("cds-control-action", t), e("cds-internal-control-group", l), e("cds-internal-control-inline", s), e("cds-internal-control-label", i), e("cds-control-message", m), e("cds-form-group", p), o.addIcons(r, c);
