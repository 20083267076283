import { setOrRemoveAttribute as t } from "@cds/core/internal";
const n = "cds-navigation-sr-text",
  e = "vertical";
function r(t, n) {
  const e = n.indexOf(t);
  return n[e + 1] ? n[e + 1] : n[0];
}
function o(t, n) {
  const e = n.indexOf(t);
  return n[e - 1] ? n[e - 1] : n[n.length - 1];
}
function a(t) {
  return t.isGroupStart ? t.expanded ? "down" : "right" : t.expandedRoot ? "left" : "right";
}
function c(n, e) {
  const r = n.querySelector("span");
  r && t(r, ["cds-layout", "display:screen-reader-only"], () => !e);
}
function s(t) {
  t.hasFocus = !1;
}
function i(t) {
  t.hasFocus = !0, t.scrollIntoView();
}
function u(t) {
  const n = t?.tagName,
    e = t?.parentElement?.parentElement;
  switch (n) {
    case "CDS-NAVIGATION-ITEM":
      return t.hasAttribute("_expanded-group");
    case "CDS-NAVIGATION-START":
      return !("CDS-NAVIGATION-GROUP" === e?.tagName && !e?.hasAttribute("expanded"));
    default:
      return !1;
  }
}
export { e as DEFAULT_NAVIGATION_LAYOUT, n as NAVIGATION_TEXT_WRAPPER, r as getNextFocusElement, o as getPreviousFocusElement, a as getToggleIconDirection, c as manageScreenReaderElements, s as removeFocus, i as setFocus, u as visibleElement };
