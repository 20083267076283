import { __decorate as t } from "tslib";
import { html as e } from "lit";
import { query as o } from "lit/decorators/query.js";
import { I18nService as s, getElementUpdates as l, isScrollable as i, i18n as a, property as d, querySlot as r, animate as n, reverseAnimation as c, AnimationModalEnterName as p } from "@cds/core/internal";
import { CdsInternalOverlay as m } from "@cds/core/internal-components/overlay";
import h from "./modal.element.scss.js";
let v = class extends m {
  constructor() {
    super(...arguments);
    this.i18n = s.keys.modal, this.closable = !0, this.isScrollable = !1, this.observers = [];
  }
  get customBumpers() {
    return [this.modalHeader, this.modalFooter];
  }
  static get styles() {
    return [...super.styles, h];
  }
  render() {
    return e`${this.backdropTemplate}<div class="modal-dialog private-host" tabindex="-1" cds-layout="m:md m@md:xl"><div cds-layout="display:screen-reader-only">${this.i18n.contentStart}</div><div class="modal-content" cds-layout="vertical gap:sm gap@md:lg align:stretch p-y:lg"><div cds-layout="horizontal gap:sm wrap:none align:vertical-center p-x:lg"><div><slot name="modal-header"></slot></div><div cds-layout="align:right"><slot name="modal-header-actions"></slot></div>${this.closable ? this.closeButtonTemplate : ""}</div><div class="modal-body" cds-layout="p-x:lg"><slot></slot></div><div cds-layout="align-stretch p-x:lg" ?hidden="${!this.modalFooter}"><slot name="modal-actions" @slotchange="${() => this.requestUpdate()}"></slot></div></div><div cds-layout="display:screen-reader-only">${this.i18n.contentEnd}</div></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.observers.push(l(this, "hidden", () => this.setScrollableProperties()));
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.observers.forEach(t => t?.disconnect());
  }
  async setScrollableProperties() {
    const t = this.content;
    !1 === this.hidden && null !== t && (await this.updateComplete, this.isScrollable = i(t), t.tabIndex = this.isScrollable ? 0 : -1, t.ariaLabel = this.isScrollable ? this.i18n.contentBox : null);
  }
};
t([a()], v.prototype, "i18n", void 0), t([d({
  type: Boolean
})], v.prototype, "closable", void 0), t([d({
  type: String
})], v.prototype, "size", void 0), t([o(".modal-body")], v.prototype, "content", void 0), t([r("cds-modal-header")], v.prototype, "modalHeader", void 0), t([r("cds-modal-actions")], v.prototype, "modalFooter", void 0), v = t([n({
  hidden: {
    true: c(p),
    false: p
  }
})], v);
export { v as CdsModal };
