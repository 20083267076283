import { baseStyles as t } from "@cds/core/internal";
import { LitElement as e, html as r } from "lit";
class s extends e {
  connectedCallback() {
    super.connectedCallback(), this.setAttribute("slot", "modal-header-actions");
  }
  render() {
    return r`<slot></slot>`;
  }
  static get styles() {
    return [t];
  }
}
export { s as CdsModalHeaderActions };
