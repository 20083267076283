import { __decorate as o } from "tslib";
import { property as t } from "@cds/core/internal";
import { CdsInternalPopup as e } from "@cds/core/internal-components/popup";
class r extends e {
  constructor() {
    super(...arguments);
    this.closable = !0, this.defaultPointerType = "angle";
  }
}
o([t({
  type: Boolean
})], r.prototype, "closable", void 0), o([t({
  type: String
})], r.prototype, "defaultPointerType", void 0);
export { r as CdsSignpost };
