import { __decorate as t } from "tslib";
import { LitElement as o, html as e } from "lit";
import { I18nService as i, createId as s, spanWrapper as n, baseStyles as a, i18n as r, property as d, state as l, querySlot as p, querySlotAll as c } from "@cds/core/internal";
import h from "./navigation-item.element.scss.js";
import { NAVIGATION_TEXT_WRAPPER as u, getToggleIconDirection as g, manageScreenReaderElements as v } from "./utils/utils.js";
const y = "cds-navigation-start";
class x extends o {
  constructor() {
    super(...arguments);
    this.i18n = i.keys.navigation, this.expandedRoot = !1, this.hasFocus = !1, this.isGroupStart = !1, this.active = !1, this.expanded = !1;
  }
  connectedCallback() {
    super.connectedCallback(), this.role = "listitem", this.id || (this.id = s());
  }
  firstUpdated(t) {
    super.firstUpdated(t);
    const o = this.shadowRoot?.querySelector("button");
    o && (this.focusElement = o), this.handleStartButtonText();
  }
  handleStartButtonText() {
    n(this.childNodes), this.querySelector("span")?.setAttribute(u, "");
  }
  renderRootStart() {
    return e`<slot @slotchange="${this.handleStartButtonText}"></slot><button aria-pressed="${this.expandedRoot}" aria-label="${this.expandedRoot ? this.i18n.navigationUnabridgedText : this.i18n.navigationAbridgedText}" id="${this.isGroupStart ? this.navigationGroupId : ""}" type="button"><div cds-layout="horizontal align:vertical-center"><span class="icon-slot" cds-layout="${this.expandedRoot ? "align:right" : "align:left"}"><slot name="cds-icon-slot">${this.startIcon ? "" : e`<cds-icon size="${!this.expandedRoot && this.isGroupStart ? "xs" : "sm"}" shape="${this.isGroupStart ? "angle" : "angle-double"}" direction="${this.toggleIconDirection}"></cds-icon>`}</slot></span></div></button>`;
  }
  renderGroupStart() {
    return e`<button aria-expanded="${this.expanded}" id="${this.isGroupStart ? this.navigationGroupId : ""}" type="button"><div cds-layout="horizontal wrap:none align:vertical-center gap:sm"><slot @slotchange="${this.handleStartButtonText}"></slot><span class="icon-slot" cds-layout="${this.expandedRoot ? "align:right" : "align:left"}"><slot name="cds-icon-slot">${this.startIcon ? "" : e`<cds-icon size="${!this.expandedRoot && this.isGroupStart ? "xs" : "sm"}" shape="${this.isGroupStart ? "angle" : "angle-double"}" direction="${this.toggleIconDirection}"></cds-icon>`}</slot></span></div></button>`;
  }
  render() {
    return e`<div class="private-host" cds-layout="horizontal align:vertical-center align:horizontal-stretch">${this.isGroupStart ? this.renderGroupStart() : this.renderRootStart()}</div>`;
  }
  static get styles() {
    return [a, h];
  }
  get toggleIconDirection() {
    return g(this);
  }
  updated(t) {
    super.updated(t), v(this, this.expandedRoot);
  }
}
t([r()], x.prototype, "i18n", void 0), t([d({
  type: Boolean
})], x.prototype, "expandedRoot", void 0), t([l({
  type: Boolean,
  reflect: !0
})], x.prototype, "hasFocus", void 0), t([d({
  type: Boolean,
  reflect: !0
})], x.prototype, "isGroupStart", void 0), t([d({
  type: String
})], x.prototype, "navigationGroupId", void 0), t([l({
  type: Boolean,
  reflect: !0
})], x.prototype, "active", void 0), t([l({
  type: Boolean,
  reflect: !0
})], x.prototype, "expanded", void 0), t([p("[cds-navigation-start-icon]", {
  assign: "cds-icon-slot"
})], x.prototype, "startIcon", void 0), t([c("[cds-navigation-sr-text]")], x.prototype, "itemText", void 0);
export { x as CdsNavigationStart, y as CdsNavigationStartTagName };
