import { __decorate as t } from "tslib";
import { querySlotAll as o } from "@cds/core/internal";
import { CdsInternalControlGroup as s, ControlLabelLayout as r } from "@cds/core/forms";
import e from "./input-group.element.scss.js";
class c extends s {
  constructor() {
    super(...arguments);
    this.isInlineControlGroup = !0;
  }
  static get styles() {
    return [super.styles, e];
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.controls.forEach(t => {
      t.responsive = !1, t.labelLayout = r.inputGroup, t.controlWidth = this.controlWidth;
    });
  }
}
t([o("cds-input, cds-select, cds-time, cds-date, cds-control, [cds-control]")], c.prototype, "controls", void 0);
export { c as CdsInputGroup };
