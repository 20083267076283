import { listenForAttributeListChange as t, onFirstInteraction as s, isNumericString as h } from "@cds/core/internal";
class e {
  constructor(t) {
    this.host = t, this.observers = [], this.host.addController(this);
  }
  get hostGrid() {
    return this.host.parentElement;
  }
  get hostRange() {
    return this.host.shadowRoot?.querySelector("cds-internal-split-handle input");
  }
  async hostConnected() {
    this.setActionWidth(), this.hostGrid.shadowRoot?.addEventListener("slotchange", () => this.setActionWidth()), this.observers.push(t(this.host, ["type"], () => this.setActionWidth())), await this.host.updateComplete, await s(this.hostGrid), this.hostRange && (this.hostRange.max = "" + parseInt(getComputedStyle(this.hostGrid).width), this.hostRange.value = "" + parseInt(getComputedStyle(this.host).width), this.hostRange.addEventListener("input", t => this.updateResizedColumnWidth(t.target.valueAsNumber)), this.hostRange.addEventListener("change", t => this.updateResizedColumnWidth(t.target.valueAsNumber)));
  }
  async hostUpdated() {
    await this.host.updateComplete, this.updateSetColumnWidth();
  }
  hostDisconnected() {
    this.observers.forEach(t => t?.disconnect());
  }
  setActionWidth() {
    if ("action" === this.host.type || "action" === this.host.getAttribute("type")) {
      this.host.width = "36px";
      const t = Array.from(this.hostGrid.columns).indexOf(this.host);
      this.hostGrid.rows.forEach(s => s.cells[t].setAttribute("type", "action"));
    }
  }
  updateSetColumnWidth() {
    this.host.width && void 0 !== this.host.ariaColIndex && (this.hostGrid.style.setProperty("--ch" + this.host.ariaColIndex, h(this.host.width) ? this.host.width + "px" : this.host.width), this.hostRange && (this.hostRange.min = "" + parseInt(this.host.width) ?? "", this.hostRange.value = this.host.width));
  }
  updateResizedColumnWidth(t) {
    this.host.dispatchEvent(new CustomEvent("resizeChange", {
      detail: t,
      bubbles: !0
    }));
    const s = Math.max(h(this.host.width) || this.host.width?.includes("px") ? parseInt(this.host.width) : 36, t);
    this.hostGrid.style.setProperty("--ch" + this.host.ariaColIndex, s + "px");
  }
}
export { e as GridColumnSizeController };
