import { Component, HostBinding,ViewChild, ElementRef } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Iproject } from '../../data/ukis-projects';
import { DataService, adjustImageLinkSize } from '../../services/data.service';
import { isClassEven } from '../../shared/utils';
import { MarkdownLinkPipe } from '../../pipes/markdown-link.pipe';
import { FooterComponent } from '../../components/footer/footer.component';
import { CdsIconModule } from '@cds/angular/icon';
import { CdsTagModule } from '@cds/angular/tag';
import { CdsButtonModule } from '@cds/angular/button';
import { CdsModalModule } from '@cds/angular/modal';
import { NgClass, NgOptimizedImage, DatePipe } from '@angular/common';

import { ClarityIcons, employeeIcon, tagIcon, codeIcon, launchpadIcon, imageGalleryIcon, displayIcon } from "@cds/core/icon";
import { FullscreenDirective } from '../../directives/fullscreen.directive';
ClarityIcons.addIcons(...[employeeIcon, tagIcon, codeIcon, launchpadIcon, imageGalleryIcon, displayIcon]);

@Component({
  selector: 'app-detail-view',
  templateUrl: './detail-view.component.html',
  styleUrls: ['./detail-view.component.scss'],
  standalone: true,
  imports: [NgClass, NgOptimizedImage, CdsModalModule, CdsButtonModule, CdsTagModule, CdsIconModule, FooterComponent, DatePipe, MarkdownLinkPipe, FullscreenDirective]
})
export class DetailViewComponent {
  @HostBinding('attr.cds-layout') cdsLayout = "vertical align:vertical-stretch wrap:none";
  @HostBinding('class') class = "route-container";
  @ViewChild("sliderContainer") sliderElement!: ElementRef;

  system: Iproject | undefined = undefined;
  public systemImages: any[] = [];


  images: Iproject['images'] = [];
  constructor(private dataSvc: DataService, private route: ActivatedRoute) { };

  getParticipant = this.dataSvc.getParticipant;
  getGitRepoName = this.dataSvc.getGitRepoName;

  ngOnInit(): void {
    const currentId = this.getIdFromRoute();
    this.system = this.dataSvc.getProject(currentId)
    if (this.system) {
      this.images = [...this.system.images];
    }
  }

  isClassEven = isClassEven

  getIdFromRoute() {
    // systems/:id' -> ['systems', 'id']
    const url = this.route.snapshot.url;
    const id = url[1];
    return id.path;
  }

  adjustImageLinkSize = adjustImageLinkSize;

  getInactiveTitle(p: Iproject) {
    return (p.inactive) ? 'Inactive' : 'Active';
  }

  getEvenImages() {
    return this.images.length % 2 == 0;
  }

  getImages() {
    return this.images;
  }
}

