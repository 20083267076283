import { __decorate as t } from "tslib";
import { property as o } from "@cds/core/internal";
import { CdsInternalControlInline as s } from "@cds/core/forms";
import e from "./toggle.element.scss.js";
class r extends s {
  constructor() {
    super(...arguments);
    this.cdsMotion = "on";
  }
  async updated(t) {
    super.updated(t), t.has("cdsMotion") && "on" === this.cdsMotion && (await this.updateComplete, this.cdsMotion = "ready");
  }
  static get styles() {
    return [...super.styles, e];
  }
}
t([o({
  type: String
})], r.prototype, "cdsMotion", void 0);
export { r as CdsToggle };
