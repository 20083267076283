import { __decorate as t } from "tslib";
import { html as r } from "lit";
import { globalStyle as o } from "@cds/core/internal";
import { CdsControl as s } from "@cds/core/forms";
import { inputStyles as e } from "@cds/core/input";
import l from "./search.global.scss.js";
class c extends s {
  constructor() {
    super(...arguments);
    this.globalStyles = l;
  }
  get prefixDefaultTemplate() {
    return r`<cds-button-action shape="search" readonly="readonly"></cds-button-action>`;
  }
  static get styles() {
    return [...super.styles, e];
  }
}
t([o()], c.prototype, "globalStyles", void 0);
export { c as CdsSearch };
