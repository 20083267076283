import { baseStyles as r } from "@cds/core/internal";
import { LitElement as t, html as e } from "lit";
import s from "./accordion.element.scss.js";
class o extends t {
  render() {
    return e`<slot></slot>`;
  }
  static get styles() {
    return [r, s];
  }
}
export { o as CdsAccordion };
