import "@cds/core/button/register.js";
import "@cds/core/icon/register.js";
import "@cds/core/divider/register.js";
import { ClarityMotion as o, AnimationNavigationGroupOpenName as r, AnimationNavigationGroupOpenConfig as e, AnimationNavigationOpenName as i, AnimationNavigationOpenConfig as s, registerElementSafely as t } from "@cds/core/internal";
import { CdsNavigationTagName as m, CdsNavigation as n } from "./navigation.element.js";
import { CdsNavigationGroupTagName as c, CdsNavigationGroup as a } from "./navigation-group.element.js";
import { CdsNavigationStartTagName as d, CdsNavigationStart as p } from "./navigation-start.element.js";
import { CdsNavigationItemTagName as g, CdsNavigationItem as j } from "./navigation-item.element.js";
import { ClarityIcons as f } from "@cds/core/icon/icon.service.js";
import { angleIcon as l } from "@cds/core/icon/shapes/angle.js";
import { angleDoubleIcon as v } from "@cds/core/icon/shapes/angle-double.js";
f.addIcons(l), f.addIcons(v), o.add(r, e), o.add(i, s), t(m, n), t(c, a), t(d, p), t(g, j);
