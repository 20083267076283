import { Component, HostBinding } from '@angular/core';

import { FooterComponent } from "../../components/footer/footer.component";

@Component({
  selector: 'app-accessibility',
  standalone: true,
  templateUrl: './accessibility.component.html',
  styleUrl: './accessibility.component.scss',
  imports: [FooterComponent]
})
export class AccessibilityComponent {
  @HostBinding('attr.cds-layout') cdsLayout = "vertical align:vertical-stretch wrap:none";
  @HostBinding('class') class = "route-container";
}
