import { __decorate as t } from "tslib";
import { LitElement as o, html as s } from "lit";
import { baseStyles as e, elementVisible as r, syncDefinedProps as i, childrenUpdateComplete as l, elementResize as a, pxToRem as n, property as p, querySlotAll as d } from "@cds/core/internal";
import { defaultFormLayout as h, getLargestPrimaryLabelWidth as c, isVerticalLayout as u } from "../utils/utils.js";
import y from "./form-group.element.scss.js";
class m extends o {
  constructor() {
    super(...arguments);
    this.layout = h, this.responsive = !0, this.validate = !1, this.observers = [];
  }
  get controlsAndGroups() {
    return [...Array.from(this.groups), ...Array.from(this.controls)];
  }
  static get styles() {
    return [e, y];
  }
  render() {
    return s`<div class="private-host" cds-layout="vertical gap:${"compact" === this.layout ? "sm" : "lg"}"><slot></slot></div>`;
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.syncLayouts(), this.setControlLabelWidths(), this.observers.push(r(this, () => this.setControlLabelWidths()));
  }
  updated(t) {
    super.updated(t), i(t, this, this.controlsAndGroups);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.observers.forEach(t => t?.disconnect());
  }
  async setControlLabelWidths() {
    "horizontal" !== this.layout && "horizontal-inline" !== this.layout && "compact" !== this.layout || (await l(this.controlsAndGroups), this.style.setProperty("--internal-label-min-width", await c(this.controlsAndGroups)), a(this, () => this.style.setProperty("--internal-label-max-width", n(this.getBoundingClientRect().width))));
  }
  syncLayouts() {
    this.addEventListener("layoutChange", t => {
      t.preventDefault(), !this.overflowElement && u(t.detail) && this.collapseForm(t.target), t.target !== this.overflowElement || u(t.detail) || this.expandForm(t.detail);
    });
  }
  collapseForm(t) {
    this.overflowElement = t, this.responsive = !1, this.layout = "vertical", t.updateComplete.then(() => t.responsive = !0);
  }
  expandForm(t) {
    this.responsive = !0, this.overflowElement = null, this.layout = t;
  }
}
t([p({
  type: String
})], m.prototype, "layout", void 0), t([p({
  type: String
})], m.prototype, "controlWidth", void 0), t([p({
  type: Boolean
})], m.prototype, "responsive", void 0), t([p({
  type: Boolean
})], m.prototype, "validate", void 0), t([d("[cds-control]")], m.prototype, "controls", void 0), t([d("[cds-control-group]")], m.prototype, "groups", void 0);
export { m as CdsFormGroup };
