import { Component } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router, RouterModule, RouterOutlet, Routes } from '@angular/router';
import { CdsModule } from '@cds/angular';

import { ClarityIcons, barsIcon, timesIcon } from "@cds/core/icon";
import { dlrIcon, ukisIcon, githubIcon } from './shared/icons';
import { technologiesAction } from './data/main.page';
ClarityIcons.addIcons(...[barsIcon, timesIcon, ukisIcon, dlrIcon, githubIcon]);

@Component({
  selector: 'app-root',
  standalone: true,
  imports: [CommonModule, RouterOutlet, RouterModule, CdsModule],
  templateUrl: './app.component.html',
  styleUrl: './app.component.scss'
})
export class AppComponent {
  title = "UKIS";
  headerRoutes: Routes;
  navOpen = false;

  technologiesAction = technologiesAction;
  constructor(public router: Router) {
    this.headerRoutes = this.router.config.filter(r => r?.data?.['header']);
  }
  toggleNav(event: any) {
    event.stopPropagation();
    this.navOpen = !this.navOpen;
  }

  closeNav(event: any) {
    event.stopPropagation();
    if (this.navOpen) {
      this.navOpen = false;
    }
  }
}
