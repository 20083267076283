import { __decorate as t } from "tslib";
import { assignSlotNames as o, property as r } from "@cds/core/internal";
import { CdsButtonAction as e } from "@cds/core/button-action";
class i extends e {
  updated(t) {
    super.updated(t), o([this, this.action ?? !1]);
  }
}
t([r({
  type: String,
  reflect: !0
})], i.prototype, "action", void 0);
export { i as CdsControlAction };
