import { __decorate as t } from "tslib";
import { LitElement as e, html as r } from "lit";
import { property as i } from "@cds/core/internal";
import { getPointer as s } from "./utils/pointer.utils.js";
import o from "./pointer.element.scss.js";
class p extends e {
  constructor() {
    super(...arguments);
    this.axisAlign = "start";
  }
  get pointerTemplate() {
    return s(this.type);
  }
  render() {
    return this.type ? s(this.type) : r`<slot></slot>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.ariaHidden = "true";
  }
  static get styles() {
    return [o];
  }
}
t([i({
  type: String
})], p.prototype, "axisAlign", void 0), t([i({
  type: String
})], p.prototype, "type", void 0);
export { p as CdsInternalPointer };
