import { Component, HostBinding } from '@angular/core';
import { introSection, ukis_features } from '../../data/ukis-features';
import { isClassEven } from '../../shared/utils';
import { FooterComponent } from '../../components/footer/footer.component';
import { CdsTagModule } from '@cds/angular/tag';
import { RouterLink } from '@angular/router';
import { NgClass, NgOptimizedImage } from '@angular/common';

@Component({
  selector: 'app-features',
  templateUrl: './features.component.html',
  styleUrls: ['./features.component.scss'],
  standalone: true,
  imports: [NgClass, NgOptimizedImage, RouterLink, CdsTagModule, FooterComponent]
})
export class FeaturesComponent {
  @HostBinding('attr.cds-layout') cdsLayout = "vertical align:vertical-stretch wrap:none";
  @HostBinding('class') class = "route-container";

  public introSection = introSection;
  public features = ukis_features;
  isClassEven = isClassEven
}
