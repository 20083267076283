import { __decorate as t } from "tslib";
import { html as i } from "lit";
import { property as e } from "@cds/core/internal";
import { CdsButtonAction as r } from "@cds/core/button-action";
import s from "./button-expand.element.scss.js";
class o extends r {
  constructor() {
    super();
    this.action = "vertical", this.expanded = !1;
  }
  static get styles() {
    return [super.styles, s];
  }
  get iconDirection() {
    return "vertical" === this.action ? this.expanded ? "down" : "right" : "horizontal" === this.action ? this.expanded ? "left" : "right" : "up";
  }
  get iconShape() {
    return "detail" === this.action ? this.expanded ? "detail-collapse" : "detail-expand" : "angle";
  }
  render() {
    return i`<div class="private-host"><slot><cds-icon .shape="${this.iconShape}" .direction="${this.iconDirection}"></cds-icon></slot></div>`;
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.readonly || (this.ariaLabel = this.ariaLabel?.length ? this.ariaLabel : this.i18n.expand);
  }
}
t([e({
  type: String
})], o.prototype, "action", void 0);
export { o as CdsButtonExpand };
