import { __decorate as t } from "tslib";
import { LitElement as e, html as o } from "lit";
import { I18nService as i, baseStyles as r, syncProps as a, syncPropsForAllItems as s, property as p, event as d, id as n, i18n as u, state as c, querySlot as l, querySlotAll as g, animate as h, AnimationNavigationGroupOpenName as v, reverseAnimation as y } from "@cds/core/internal";
import m from "./navigation-group.element.scss.js";
const x = "cds-navigation-group";
let G = class extends e {
  constructor() {
    super(...arguments);
    this.cdsMotion = "on", this.i18n = i.keys.navigation, this.expanded = !1, this.expandedGroup = !1, this.hasFocus = !1;
  }
  get isGroupStart() {
    return !!this.groupStart;
  }
  render() {
    return o`<div class="private-host" cds-layout="vertical wrap:none align:horizontal-stretch"><slot name="group-start"></slot><div class="group-items-wrapper" aria-hidden="${!this.expandedGroup}" aria-labelledby="${this.navigationGroupId}"><div class="group-items-container" aria-expanded="${this.expanded}"><div class="navigation-group-items" cds-layout="vertical wrap:none align:horizontal-stretch" role="list"><slot></slot></div></div></div></div>`;
  }
  static get styles() {
    return [r, m];
  }
  toggle() {
    this.expandedChange.emit(!this.expanded);
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.groupStart.removeEventListener("click", this.toggle.bind(this));
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.groupStart && this.groupStart.addEventListener("click", this.toggle.bind(this));
  }
  updated(t) {
    super.updated(t), t.has("expanded") && (this.expandedGroup = this.expanded), this.groupStart && a(this.groupStart, this, {
      active: !0,
      expanded: !0,
      isGroupStart: this.isGroupStart,
      navigationGroupId: !0
    }), s(Array.from(this.groupItems), this, {
      expandedGroup: !0
    });
  }
};
t([p({
  type: String
})], G.prototype, "cdsMotion", void 0), t([d()], G.prototype, "expandedChange", void 0), t([d()], G.prototype, "cdsMotionChange", void 0), t([n()], G.prototype, "navigationGroupId", void 0), t([u()], G.prototype, "i18n", void 0), t([c()], G.prototype, "isGroupStart", null), t([p({
  type: Boolean,
  reflect: !0
})], G.prototype, "expanded", void 0), t([p({
  type: Boolean
})], G.prototype, "active", void 0), t([c()], G.prototype, "expandedGroup", void 0), t([l(":scope > cds-navigation-start", {
  assign: "group-start"
})], G.prototype, "groupStart", void 0), t([g(":scope > cds-navigation-item")], G.prototype, "groupItems", void 0), t([g(":scope > cds-navigation-group")], G.prototype, "nestedGroups", void 0), G = t([h({
  expanded: {
    true: v,
    false: y(v)
  }
})], G);
export { G as CdsNavigationGroup, x as CdsNavigationGroupTagName };
