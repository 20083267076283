import { onChildListMutation as t, listenForAttributeListChange as s, isNumericString as i, notProductionEnvironment as e, LogService as h } from "@cds/core/internal";
class o {
  constructor(t) {
    this.host = t, this.observers = [], this.host.addController(this);
  }
  get columns() {
    return Array.from(this._columns ?? []);
  }
  get visibleColumns() {
    return this.columns.filter(t => !t.hidden);
  }
  get lastVisibleColumn() {
    return this.visibleColumns["rtl" === this.host.getAttribute("dir") ? 0 : this.visibleColumns.length - 1];
  }
  async hostConnected() {
    await this.host.updateComplete, this.updateLayout(), this.host.addEventListener("resizeChange", () => this.initializeColumnWidths(), {
      once: !0,
      capture: !0
    }), this.observers.push(t(this.host, async t => {
      await this.host.updateComplete, t && this.columnAddedOrRemoved(t) && this.updateLayout();
    })), this.observers.push(s(this.host, ["hidden"], () => this.updateLayout()));
  }
  hostUpdated() {
    this.host.height && this.host.style.setProperty("--body-height", i(this.host.height) ? this.host.height + "px" : this.host.height);
  }
  hostDisconnected() {
    this.observers.forEach(t => t?.disconnect());
  }
  columnAddedOrRemoved(t) {
    return [...Array.from(t.removedNodes), ...Array.from(t.addedNodes)].find(t => "CDS-GRID-COLUMN" === t.tagName);
  }
  initializeColumnWidths() {
    "fixed" === this.host.columnLayout && (this.visibleColumns.filter(t => t.width).forEach(t => this.host.style.setProperty("--ch" + t.ariaColIndex, i(t.width) ? t.width + "px" : t.width)), this.visibleColumns.filter(t => !t.width && parseInt(t.ariaColIndex) !== this.columns.length).forEach(t => this.host.style.setProperty("--ch" + t.ariaColIndex, parseInt(getComputedStyle(t).width) + "px")), this.host.style.setProperty("--ch" + this.lastVisibleColumn.ariaColIndex, `minmax(${this.getLastColumnWidth() ?? parseInt(getComputedStyle(this.lastVisibleColumn).width) + "px"}, 100%)`));
  }
  getLastColumnWidth() {
    return i(this.lastVisibleColumn.width) ? this.lastVisibleColumn.width + "px" : this.lastVisibleColumn.width ? this.lastVisibleColumn.width : null;
  }
  updateLayout() {
    this._columns = this.host.columns, this.validateColumnLayout(), this.createColumnGrids(), this.setColumnDividers();
  }
  createColumnGrids() {
    const t = this.columns.filter(t => !t.hidden).reduce((t, s) => {
      const e = i(s.width) ? s.width + "px" : s.width;
      return `${t} var(--ch${s.ariaColIndex}, ${e || "1fr"})`;
    }, "");
    this.host.style.setProperty("--ch-grid", t);
  }
  setColumnDividers() {
    this.visibleColumns.forEach((t, s) => {
      t.removeAttribute("draggable-hidden"), "action" === t.type && "action" === this.visibleColumns[s + 1]?.type && t.setAttribute("draggable-hidden", "");
    }), this.lastVisibleColumn?.setAttribute("draggable-hidden", "");
  }
  validateColumnLayout() {
    if (e()) {
      const t = Array.from(this.host.rows[0]?.cells ?? []).filter(t => !t.hidden);
      this.visibleColumns.length !== t?.length && 0 !== t?.length && h.error(`Error: column mismatch, ${this.visibleColumns.length} visible column headers with ${t.length} visible cells`);
    }
  }
}
export { o as GridLayoutController };
