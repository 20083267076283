import { __decorate as t } from "tslib";
import { createId as e, baseStyles as o, state as r } from "@cds/core/internal";
import { LitElement as s, html as a } from "lit";
import d from "./accordion-header.element.scss.js";
class n extends s {
  constructor() {
    super(...arguments);
    this.expanded = !1;
  }
  connectedCallback() {
    super.connectedCallback(), this.setAttribute("slot", "accordion-header"), this.id || (this.id = e());
  }
  render() {
    return a`<div class="private-host" cds-layout="horizontal gap:sm align:vertical-center wrap:none"><cds-button-expand class="expand-button" .expanded="${this.expanded}" readonly="readonly" action="vertical"></cds-button-expand><div cds-layout="align:stretch"><slot></slot></div></div>`;
  }
  static get styles() {
    return [o, d];
  }
}
t([r()], n.prototype, "expanded", void 0);
export { n as CdsAccordionHeader };
