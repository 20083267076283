import { __decorate as r } from "tslib";
import { css as e } from "lit";
import { globalStyle as t } from "@cds/core/internal";
import { CdsInternalPanel as o } from "@cds/core/internal-components/panel";
import c from "./card.element.scss.js";
class s extends o {
  constructor() {
    super(...arguments);
    this.globalStyles = e`[cds-card-remove-margin]{margin-left:calc(-1 * var(--card-remove-margin));width:calc(100% + calc(var(--card-remove-margin) * 2))}`;
  }
  static get styles() {
    return [...super.styles, c];
  }
  connectedCallback() {
    super.connectedCallback(), this.role = "region";
  }
}
r([t()], s.prototype, "globalStyles", void 0);
export { s as CdsCard };
