import { __decorate as t } from "tslib";
import { LitElement as s, html as r } from "lit";
import { createId as e, baseStyles as o, state as a, querySlot as i } from "@cds/core/internal";
import l from "./breadcrumb.element.scss.js";
class n extends s {
  constructor() {
    super(...arguments);
    this.navItems = [];
  }
  render() {
    return r`<div class="private-host"><ol cds-layout="horizontal gap:xs align:vertical-center">${this.slotNames.map(t => r`<li cds-layout="horizontal gap:xs align:vertical-center"><slot name="${t}"></slot><span part="separator" aria-hidden="true">${this.separator}</span></li>`)}</ol></div><slot @slotchange="${this.assignSlots}"></slot>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.role = "navigation";
  }
  get separator() {
    if (this.customSeparator) {
      const t = this.customSeparator.cloneNode(!0);
      return t.removeAttribute("slot"), t;
    }
    return r`/`;
  }
  assignSlots() {
    this.navItems = Array.from(this.children).filter(t => "cds-separator" !== t.getAttribute("slot")).map(t => (t.setAttribute("slot", e()), t));
  }
  get slotNames() {
    return this.navItems.map(t => t.slot);
  }
  static get styles() {
    return [o, l];
  }
}
t([a({
  type: Array
})], n.prototype, "navItems", void 0), t([i('[slot="cds-separator"]')], n.prototype, "customSeparator", void 0);
export { n as CdsBreadcrumb };
