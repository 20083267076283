import { __decorate as t } from "tslib";
import { baseStyles as r, property as o } from "@cds/core/internal";
import { LitElement as i, html as e } from "lit";
import s from "./divider.element.scss.js";
class n extends i {
  constructor() {
    super(...arguments);
    this.orientation = "horizontal";
  }
  render() {
    return e`<div class="private-host" role="separator" aria-orientation="${this.orientation}"></div>`;
  }
  static get styles() {
    return [r, s];
  }
}
t([o({
  type: String
})], n.prototype, "orientation", void 0);
export { n as CdsDivider };
