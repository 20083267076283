import { Directive, ElementRef, Renderer2 } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

import { ClarityIcons, timesIcon } from "@cds/core/icon";
ClarityIcons.addIcons(timesIcon);

/** https://sreyaj.dev/fullscreen-toggle-angular-using-directives 
 * 
 * usage:
 * - fullscreen-directive-style.scss
 * - and:
 * <div maximize #maximize="maximize">
 *    <header>
 *       <button class="fullscreen-directive-close"><cds-icon shape="times" size="lg" (click)="maximize.minimize()" autofocus></cds-icon></button>
 *    </header>
 *    <div>...</div>  
 * </div>
*/
@Directive({
  selector: "[maximize]",
  exportAs: "maximize",
  standalone: true
})
export class FullscreenDirective {

  private isMaximizedSubject = new BehaviorSubject(false);
  isMaximized$ = this.isMaximizedSubject.pipe();
  constructor(private el: ElementRef, private renderer: Renderer2) { }

  toggle() {
    this.isMaximizedSubject?.getValue() ? this.minimize() : this.maximize();
  }
  maximize() {
    if (this.el) {
      this.isMaximizedSubject.next(true);
      this.renderer.addClass(this.el.nativeElement, "maximized");
    }
  }
  minimize() {
    if (this.el) {
      this.isMaximizedSubject.next(false);
      this.renderer.removeClass(this.el.nativeElement, "maximized");
    }
  }

}
