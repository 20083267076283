import { Component } from '@angular/core';

import { Router, RouterModule, Routes } from '@angular/router';

import { ClarityIcons } from "@cds/core/icon";
import { dlrIcon, ukisIcon, eocIcon } from '../../shared/icons';
import { CdsModule } from '@cds/angular';
ClarityIcons.addIcons(...[ukisIcon, dlrIcon, eocIcon]);

@Component({
  selector: 'app-footer',
  standalone: true,
  imports: [RouterModule, CdsModule],
  templateUrl: './footer.component.html',
  styleUrl: './footer.component.scss'
})
export class FooterComponent {
  footerRoutes: Routes;
  navOpen = false;
  constructor(public router: Router) {
    this.footerRoutes = this.router.config.filter(r => r?.data?.['footer']);
  }
}
