import { __decorate as t } from "tslib";
import { LitElement as o, html as s } from "lit";
import { query as e } from "lit/decorators/query.js";
import { setAttributes as i, syncProps as a, syncDefinedProps as r, describeElementByElements as l, calculateOptimalLayout as n, baseStyles as d, property as p, querySlot as c, querySlotAll as u, id as h, event as y, responsive as g } from "@cds/core/internal";
import m from "./control-group.element.scss.js";
import { defaultFormLayout as v, defaultControlWidth as b, getStatusIcon as L, inlineControlListIsWrapped as f, controlIsWrapped as C, getCurrentMessageStatus as $, formLayouts as z } from "../utils/utils.js";
let A = class extends o {
  constructor() {
    super(...arguments);
    this.status = "neutral", this.layout = v, this.controlAlign = "left", this.disabled = !1, this.controlWidth = b, this.responsive = !0, this.isInlineControlGroup = !1, this.isControlGroup = !0;
  }
  get messagesTemplate() {
    return s`<div ?hidden="${0 === this.messages.length}" cds-layout="horizontal align:shrink gap:xs wrap:none" class="messages-container">${this.isInlineControlGroup ? "" : L(this.status)}<div class="messages"><slot name="message" @slotchange="${this.updateControlMessages}"></slot></div></div>`;
  }
  get controlsTemplate() {
    return this.isInlineControlGroup ? s`<div cds-layout="horizontal gap:xs align:horizontal-stretch" class="input-container"><div class="controls" cds-layout="horizontal align:horizontal-stretch wrap:none"><slot name="controls"></slot></div>${L(this.status)}</div>` : s`<div cds-layout="horizontal align:shrink" class="input-container"><div class="controls" cds-layout="${this.inlineControlLayout}"><slot name="controls"></slot></div></div>`;
  }
  get inlineControlLayout() {
    return `${this.layout.includes("inline") || "compact" === this.layout ? "horizontal gap:sm" : "vertical gap:xs"} ${this.layout.includes("vertical") ? "" : "wrap:none"}`;
  }
  get primaryLabelLayout() {
    return this.layout.includes("vertical") ? "vertical gap:xs" : "horizontal gap:lg";
  }
  get controlMessageLayout() {
    return ("compact" === this.layout ? "horizontal" : "vertical") + " gap:xs wrap:none align:stretch";
  }
  render() {
    return s`<div class="private-host" cds-layout="${this.primaryLabelLayout}"><cds-internal-control-label .disabled="${this.disabled}" cds-layout="align:top" action="primary"><slot name="label"></slot></cds-internal-control-label><div class="control-message-container" cds-layout="${this.controlMessageLayout}">${this.controlsTemplate} ${this.messagesTemplate}</div></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), i(this, ["role", "group"], ["cds-control-group", ""]);
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.associateLabelAndGroup(), this.setupResponsive();
  }
  updated(t) {
    super.updated(t), t.set("isControlGroup", !0), this.controls.forEach(t => t.isControlGroup = !0), this.messages.forEach(o => a(o, this, {
      disabled: t.has("disabled")
    })), r(t, this, Array.from(this.controls));
  }
  get layoutStable() {
    return !f(Array.from(this.controls), this.layout) && !C(this.controlSlot, this.controlLabel, this.layout);
  }
  associateLabelAndGroup() {
    this.setAttribute("aria-labelledby", this.groupLabelId), this.label.setAttribute("id", this.groupLabelId);
  }
  async updateControlMessages() {
    l(this, Array.from(this.messages)), this.status = await $(Array.from(this.messages));
  }
  setupResponsive() {
    if (this.responsive) {
      const t = {
        layouts: z,
        initialLayout: this.layout
      };
      this.addEventListener("cdsResizeChange", () => n(this, t).then(() => this.layoutChange.emit(this.layout, {
        bubbles: !0
      })));
    }
  }
};
A.styles = [d, m], t([p({
  type: String
})], A.prototype, "status", void 0), t([p({
  type: String
})], A.prototype, "layout", void 0), t([p({
  type: String
})], A.prototype, "controlAlign", void 0), t([p({
  type: Boolean
})], A.prototype, "disabled", void 0), t([p({
  type: String
})], A.prototype, "controlWidth", void 0), t([p({
  type: Boolean
})], A.prototype, "responsive", void 0), t([c("label", {
  assign: "label",
  required: "warning",
  requiredMessage: "To meet a11y standards a <label> should be provided"
})], A.prototype, "label", void 0), t([u("cds-control, [cds-control]")], A.prototype, "controls", void 0), t([u("cds-control-message")], A.prototype, "messages", void 0), t([e("cds-internal-control-label[action=primary]", !0)], A.prototype, "controlLabel", void 0), t([e(".controls", !0)], A.prototype, "controlSlot", void 0), t([h()], A.prototype, "groupLabelId", void 0), t([y()], A.prototype, "layoutChange", void 0), A = t([g()], A);
export { A as CdsInternalControlGroup };
