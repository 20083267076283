import { __decorate as t } from "tslib";
import { LitElement as e, html as s } from "lit";
import { query as i } from "lit/decorators/query.js";
import { getElementLanguageDirection as o, baseStyles as a, hasAriaLabelTypeAttr as l, syncProps as r, describeElementByElements as n, getElementUpdates as p, elementVisible as u, childrenUpdateComplete as d, pxToRem as h, calculateOptimalLayout as c, property as y, state as f, querySlot as g, querySlotAll as b, event as m, responsive as v } from "@cds/core/internal";
import L from "./control.element.scss.js";
import { syncHTML5Validation as x } from "../utils/validate.js";
import { defaultControlWidth as C, defaultFormLayout as A, getStatusIcon as T, associateInputAndLabel as $, associateInputToDatalist as S, getCurrentMessageStatus as I, controlIsWrapped as P, formLayouts as k } from "../utils/utils.js";
var w;
!function (t) {
  t.default = "default", t.ariaLabel = "aria-label", t.inputGroup = "input-group", t.hiddenLabel = "hidden-label";
}(w || (w = {}));
let D = class extends e {
  constructor() {
    super(...arguments);
    this.status = "neutral", this.controlWidth = C, this.validate = !1, this.responsive = !0, this._layout = A, this.focused = !1, this.disabled = !1, this.readonly = !1, this.fixedControlWidth = !1, this.supportsPrefixSuffixActions = !0, this.labelLayout = w.default, this.observers = [];
  }
  get layout() {
    return this._layout;
  }
  set layout(t) {
    const e = this._layout;
    this._layout = t ? t.replace("-inline", "") : A, this.requestUpdate("layout", e);
  }
  get isRTL() {
    return "rtl" === o(this);
  }
  static get styles() {
    return [a, L];
  }
  get hasAriaLabelTypeAttr() {
    return l(this.inputControl);
  }
  get hasStatusIcon() {
    return this.labelLayout !== w.inputGroup && ("error" === this.status || "success" === this.status);
  }
  render() {
    return s`${this.labelLayout === w.hiddenLabel || this.labelLayout === w.inputGroup ? s`<span cds-layout="display:screen-reader-only"><slot name="label" @slotchange="${() => this.associateInputAndLabel()}"></slot></span>` : ""}<div cds-layout="${"vertical" === this.layout ? "vertical gap:xs" : "horizontal gap:lg"} align:stretch" class="private-host ${this.isRTL ? "rtl" : ""}">${this.primaryLabelTemplate}<div class="input-message-container" cds-layout="
          wrap:none
          ${"compact" === this.layout ? "horizontal" : "vertical"}
          ${"stretch" !== this.controlWidth || this.fixedControlWidth ? "" : "align:horizontal-stretch"}
          ${this.messages?.length ? "gap:xs" : ""}"><div cds-layout="horizontal gap:xs wrap:none"><div cds-layout="horizontal align:top wrap:none ${"shrink" === this.controlWidth || this.fixedControlWidth ? "align:shrink" : "align:horizontal-stretch"}" class="${this.hasStatusIcon ? "input-container with-status-icon" : "input-container"}">${this.inputTemplate} ${this.prefixTemplate}<slot name="input"></slot>${this.suffixTemplate}</div>${this.hasStatusIcon ? T(this.status) : ""}</div>${this.messagesTemplate}<slot name="datalist" @slotchange="${() => this.associateInputToDatalist()}"></slot></div></div>`;
  }
  get inputTemplate() {
    return s``;
  }
  get prefixDefaultTemplate() {
    return null;
  }
  get suffixDefaultTemplate() {
    return null;
  }
  get isGenericControl() {
    return "cds-control" === this.tagName.toLowerCase();
  }
  get hasControlActions() {
    return this.controlActions.length > 0 || this.prefixDefaultTemplate || this.suffixDefaultTemplate;
  }
  get primaryLabelTemplate() {
    return s`${this.labelLayout === w.default ? s`<cds-internal-control-label .disabled="${this.disabled}" cds-layout="align:shrink align:top" action="primary"><slot name="label" @slotchange="${() => this.associateInputAndLabel()}"></slot></cds-internal-control-label>` : ""}`;
  }
  get messagesTemplate() {
    return s`<div cds-layout="${"compact" === this.layout ? "align:shrink" : ""}" class="messages"><slot name="message"></slot></div>`;
  }
  get prefixTemplate() {
    return s`<div cds-layout="align:shrink align:vertical-center" class="prefix"><div cds-layout="horizontal gap:xxs">${this.prefixDefaultTemplate}<slot name="prefix"></slot></div></div>`;
  }
  get suffixTemplate() {
    return s`<div cds-layout="align:shrink align:vertical-center" class="suffix"><div cds-layout="horizontal gap:xxs"><slot name="suffix"></slot>${this.suffixDefaultTemplate}</div></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.setAttribute("cds-control", "");
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.setupHostAttributes(), this.setupHTML5Validation(), this.setupPositioningListeners(), this.setupResponsive(), this.setupDescribedByUpdates(), this.setupLabelLayout(), this.assignSlotIfInControlGroup();
  }
  updated(t) {
    super.updated(t), this.messages.forEach(e => r(e, this, {
      disabled: t.has("disabled")
    })), r(this.inputControl, this, {
      disabled: t.has("disabled")
    });
  }
  disconnectedCallback() {
    super.disconnectedCallback(), this.observers.forEach(t => t?.disconnect());
  }
  associateInputAndLabel() {
    $(this.inputControl, this.label);
  }
  associateInputToDatalist() {
    S(this.inputControl, this.datalistControl);
  }
  setupDescribedByUpdates() {
    this.messageSlot?.addEventListener("slotchange", async () => {
      n(this.inputControl, Array.from(this.messages)), I(Array.from(this.messages)).then(t => this.status = t);
    });
  }
  setupHostAttributes() {
    this.inputControl.addEventListener("focusin", () => this.focused = !0), this.inputControl.addEventListener("focusout", () => this.focused = !1), this.observers.push(p(this.inputControl, "disabled", t => this.disabled = "" === t || t), p(this.inputControl, "aria-disabled", t => this.disabled = "true" === t), p(this.inputControl, "readonly", t => this.readonly = "" === t || t));
  }
  setupHTML5Validation() {
    !this.inputControl?.form?.noValidate && this.validate && x(this, Array.from(this.messages));
  }
  setupPositioningListeners() {
    this.setActionOffsetPadding(), this.observers.push(u(this.inputControl, () => this.setActionOffsetPadding()));
  }
  async setActionOffsetPadding() {
    const t = this.prefixAction?.updateComplete,
      e = this.suffixAction?.updateComplete;
    if ((await t) || Promise.resolve(!0), (await e) || Promise.resolve(!0), await d(this.controlActions), !this.isGenericControl && this.supportsPrefixSuffixActions && this.hasControlActions) {
      const t = h(this.prefixAction.getBoundingClientRect().width + 6),
        e = h(this.suffixAction.getBoundingClientRect().width + 6);
      this.inputControl.style.setProperty("padding-left", this.isRTL ? e : t, "important"), this.inputControl.style.setProperty("padding-right", this.isRTL ? t : e, "important");
    }
  }
  get layoutStable() {
    return this.labelLayout !== w.default || !P(this.inputControl, this.controlLabel, this.layout);
  }
  setupResponsive() {
    if (this.responsive && this.labelLayout === w.default && this.controlLabel) {
      const t = {
        layouts: k,
        initialLayout: this.layout
      };
      this.addEventListener("cdsResizeChange", () => {
        c(this, t).then(t => {
          t && this.layoutChange.emit(this.layout, {
            bubbles: !0
          });
        });
      });
    }
  }
  setupLabelLayout() {
    this.label?.getAttribute("cds-layout")?.includes("display:screen-reader-only") && (this.labelLayout = w.hiddenLabel), this.hasAriaLabelTypeAttr && (this.labelLayout = w.ariaLabel);
  }
  assignSlotIfInControlGroup() {
    this.parentElement?.hasAttribute("cds-control-group") && this.setAttribute("slot", "controls");
  }
};
t([y({
  type: String
})], D.prototype, "status", void 0), t([y({
  type: String
})], D.prototype, "controlWidth", void 0), t([y({
  type: Boolean
})], D.prototype, "validate", void 0), t([y({
  type: Boolean
})], D.prototype, "responsive", void 0), t([y({
  type: String
})], D.prototype, "layout", null), t([f({
  type: Boolean,
  reflect: !0
})], D.prototype, "focused", void 0), t([f({
  type: Boolean,
  reflect: !0
})], D.prototype, "disabled", void 0), t([f({
  type: Boolean,
  reflect: !0
})], D.prototype, "readonly", void 0), t([f()], D.prototype, "fixedControlWidth", void 0), t([f()], D.prototype, "supportsPrefixSuffixActions", void 0), t([f()], D.prototype, "isRTL", null), t([f()], D.prototype, "labelLayout", void 0), t([g("input, select, textarea, [cds-control]", {
  required: "error",
  requiredMessage: "input element is missing",
  assign: "input"
})], D.prototype, "inputControl", void 0), t([g("label", {
  required: "error",
  requiredMessage: "To meet a11y standards either a <label> or input[aria-label] should be provided.",
  assign: "label",
  exemptOn: t => t.hasAriaLabelTypeAttr
})], D.prototype, "label", void 0), t([i("cds-internal-control-label[action=primary]")], D.prototype, "controlLabel", void 0), t([g("datalist", {
  assign: "datalist"
})], D.prototype, "datalistControl", void 0), t([b("cds-control-message")], D.prototype, "messages", void 0), t([b("[cds-button-action]")], D.prototype, "controlActions", void 0), t([i(".prefix")], D.prototype, "prefixAction", void 0), t([i(".suffix")], D.prototype, "suffixAction", void 0), t([i(".messages")], D.prototype, "messageSlot", void 0), t([m()], D.prototype, "layoutChange", void 0), D = t([v()], D);
export { D as CdsControl, w as ControlLabelLayout };
