import { LitElement as t, html as e } from "lit";
import { baseStyles as s } from "@cds/core/internal";
import r from "./panel.element.scss.js";
const n = "cds-internal-panel";
class o extends t {
  static get styles() {
    return [s, r];
  }
  render() {
    return e`<div class="private-host"><slot></slot></div>`;
  }
}
export { o as CdsInternalPanel, n as CdsInternalPanelTagName };
