import { IconShapeTuple } from "@cds/core/icon/interfaces/icon.interfaces";

export const ukisIcon: IconShapeTuple = ['ukis-icon', `<svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" class="has-solid"
xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" role="img">
<title>ukis</title>
<path class="clr-i-solid clr-i-solid-path-1" d="M18,31.5c4.7,0,8.9-2.4,11.3-6.1H6.7C9.1,29.1,13.3,31.5,18,31.5z"/>
<path class="clr-i-solid clr-i-solid-path-2" d="M4.5,17.1c0,0.3,0,0.6,0,0.9c0,1.7,0.3,3.4,0.9,4.9h21.2L4.5,17.1z"/>
<path class="clr-i-solid clr-i-solid-path-3" d="M31,21.5c0.3-1.1,0.5-2.3,0.5-3.5c0-3-1-5.7-2.6-7.9L8.7,15.6L31,21.5z"/>
<path class="clr-i-solid clr-i-solid-path-4" d="M18,4.5c-6,0-11.2,4-12.9,9.5l22-6C24.7,5.8,21.5,4.5,18,4.5z"/>
</svg>`];

export const eocIcon: IconShapeTuple = ['eoc-icon', `<svg version="1.1" viewBox="0 -180 580 580" preserveAspectRatio="xMidYMid meet" class="has-solid"
xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" role="img">
<title>eoc</title>
<path class="clr-i-solid clr-i-solid-path-1" d="M284,210c-55.1,0-100-44.9-100-100S228.9,10,284,10s100,44.9,100,100S339.1,210,284,210z M284,28.2
c-45.1,0-81.8,36.7-81.8,81.8s36.7,81.8,81.8,81.8s81.8-36.7,81.8-81.8S329.1,28.2,284,28.2z M566,180.7l-12.9-12.9
c-31.9,31.9-83.8,31.9-115.7,0c-31.9-31.9-31.9-83.8,0-115.7c31.9-31.9,83.8-31.9,115.7,0L566,39.3c-39-39-102.5-39-141.5,0
s-39,102.5,0,141.5c19.5,19.5,45.1,29.3,70.7,29.3S546.5,200.2,566,180.7z M184.7,180.7l-12.9-12.9c-31.9,31.9-83.8,31.9-115.7,0
c-15.5-15.5-24-36-24-57.8c0-21.9,8.5-42.4,24-57.8c28.6-28.6,73.4-31.8,105.5-8.7l-67.8,75.2l13.5,12.2l80.3-89.1l-6.7-6.1
C141.4,0.1,80.9,1.7,43.3,39.3C24.4,58.2,14,83.3,14,110c0,26.7,10.4,51.8,29.3,70.7C62.8,200.2,88.4,210,114,210
C139.6,210,165.2,200.2,184.7,180.7z"/>
</svg>`];

export const dlrIcon: IconShapeTuple = ['dlr-icon', `<svg version="1.1" viewBox="0 0 36 36" preserveAspectRatio="xMidYMid meet" class="has-solid"
xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" focusable="false" role="img">
<title>dlr</title>
<path class="clr-i-solid clr-i-solid-path-1" d="M25.8,13.2V3.8l-9.4,9.4h-8l-7.7,7.7l9.4,0v9.4l9.4-9.4h8l7.7-7.7H25.8L25.8,13.2z M23.9,8.5v4.7h-4.7L23.9,8.5
L23.9,8.5z M5.4,18.9l3.8-3.8h5.3l-3.8,3.8H5.4L5.4,18.9z M12.1,25.6v-4.8h4.8L12.1,25.6L12.1,25.6z M18.8,18.9h-5.4l3.8-3.8h5.4
L18.8,18.9L18.8,18.9z M26.8,18.9h-5.2l3.8-3.8h5.2L26.8,18.9L26.8,18.9z M19.6,26.5h1.6c1.8,0,3.3,0.6,3.3,2.9
c0,2.3-1.6,2.9-3.3,2.9h-1.6V26.5L19.6,26.5z M20.8,31.3h0.6c1,0,1.9-0.7,1.9-2c0-1.2-0.9-2-1.9-2h-0.6V31.3L20.8,31.3z M25.7,26.5
h1.1v4.8h2.2v0.9h-3.4V26.5L25.7,26.5z M30.1,26.5h1.2c1.2,0,2.7,0,2.7,1.6c0,0.7-0.5,1.2-1.2,1.3v0c0.3,0,0.5,0.3,0.6,0.6l0.9,2.2
h-1.3l-0.7-1.8c-0.2-0.4-0.3-0.6-0.8-0.6h-0.4v2.4h-1.1V26.5L30.1,26.5z M31.3,28.9h0.4c0.6,0,1.2-0.1,1.2-0.8
c0-0.7-0.6-0.7-1.2-0.7h-0.4V28.9L31.3,28.9z"/>
</svg>`];

export const githubIcon: IconShapeTuple = ['github-icon', `<svg version="1.1" viewBox="0 0 24 24" height="32" width="32" data-view-component="true" class="has-solid">
    <path class="clr-i-solid clr-i-solid-path-1" d="M12.5.75C6.146.75 1 5.896 1 12.25c0 5.089 3.292 9.387 7.863 10.91.575.101.79-.244.79-.546 0-.273-.014-1.178-.014-2.142-2.889.532-3.636-.704-3.866-1.35-.13-.331-.69-1.352-1.18-1.625-.402-.216-.977-.748-.014-.762.906-.014 1.553.834 1.769 1.179 1.035 1.74 2.688 1.25 3.349.948.1-.747.402-1.25.733-1.538-2.559-.287-5.232-1.279-5.232-5.678 0-1.25.445-2.285 1.178-3.09-.115-.288-.517-1.467.115-3.048 0 0 .963-.302 3.163 1.179.92-.259 1.897-.388 2.875-.388.977 0 1.955.13 2.875.388 2.2-1.495 3.162-1.179 3.162-1.179.633 1.581.23 2.76.115 3.048.733.805 1.179 1.825 1.179 3.09 0 4.413-2.688 5.39-5.247 5.678.417.36.776 1.05.776 2.128 0 1.538-.014 2.774-.014 3.162 0 .302.216.662.79.547C20.709 21.637 24 17.324 24 12.25 24 5.896 18.854.75 12.5.75Z"></path>
</svg>`];