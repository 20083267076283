import { __decorate as t } from "tslib";
import { LitElement as o, html as r } from "lit";
import { I18nService as e, baseStyles as s, i18n as i } from "@cds/core/internal";
import l from "./grid-footer.element.scss.js";
class n extends o {
  constructor() {
    super(...arguments);
    this.i18n = e.keys.grid;
  }
  render() {
    return r`<div class="private-host"><slot><div cds-layout="display:screen-reader-only">${this.i18n.footerEnd}</div></slot></div>`;
  }
  connectedCallback() {
    super.connectedCallback(), this.slot = "footer";
  }
}
n.styles = [s, l], t([i()], n.prototype, "i18n", void 0);
export { n as CdsGridFooter };
