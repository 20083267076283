import "@cds/core/internal-components/split-handle/register.js";
import "@cds/core/button-action/register.js";
import "@cds/core/icon/register.js";
import { registerElementSafely as r } from "@cds/core/internal";
import { ClarityIcons as o } from "@cds/core/icon/icon.service.js";
import { filterIcon as e } from "@cds/core/icon/shapes/filter.js";
import { viewColumnsIcon as i } from "@cds/core/icon/shapes/view-columns.js";
import { timesIcon as s } from "@cds/core/icon/shapes/times.js";
import { pinIcon as c } from "@cds/core/icon/shapes/pin.js";
import { unpinIcon as m } from "@cds/core/icon/shapes/unpin.js";
import { CdsGrid as t } from "./grid/grid.element.js";
import { CdsGridRow as d } from "./row/grid-row.element.js";
import { CdsGridCell as n } from "./cell/grid-cell.element.js";
import { CdsGridColumn as p } from "./column/grid-column.element.js";
import { CdsGridDetail as l } from "./detail/grid-detail.element.js";
import { CdsGridFooter as g } from "./footer/grid-footer.element.js";
import { CdsGridPagination as a } from "./pagination/grid-pagination.element.js";
import { CdsGridPlaceholder as f } from "./placeholder/grid-placeholder.element.js";
o.addIcons(e, i, s, c, m), r("cds-grid", t), r("cds-grid-row", d), r("cds-grid-cell", n), r("cds-grid-column", p), r("cds-grid-detail", l), r("cds-grid-footer", g), r("cds-grid-pagination", a), r("cds-grid-placeholder", f);
