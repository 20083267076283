import { __decorate as t } from "tslib";
import { html as e } from "lit";
import { I18nService as i, i18n as o, property as s, state as n } from "@cds/core/internal";
import { CdsControl as l } from "@cds/core/forms";
import r from "./file.element.scss.js";
class a extends l {
  constructor() {
    super(...arguments);
    this.i18n = i.keys.file, this.buttonLabel = this.i18n.browse, this.fixedControlWidth = !0, this.supportsPrefixSuffixActions = !1;
  }
  static get styles() {
    return [...super.styles, r];
  }
  get inputTemplate() {
    return e`<div cds-layout="horizontal gap:sm align:vertical-center"><cds-button size="sm" action="outline" @click="${() => this.label.click()}" ?disabled="${this.disabled}"><cds-icon shape="folder" aria-hidden="true"></cds-icon><span>${this.buttonLabelForSelection || this.buttonLabel}</span></cds-button>${this.clearFilesControlTemplate}</div>`;
  }
  get clearFilesControlTemplate() {
    return this.inputControl.files?.length && !this.disabled ? e`<cds-button-action shape="times" @click="${() => this.clearFiles()}" aria-label="${this.i18n.removeFile}"></cds-button-action>` : e``;
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.inputControl.addEventListener("change", t => {
      t.isTrusted && this.updateLabelAndFocus(t.target.files);
    });
  }
  clearFiles(t = !0) {
    this.buttonLabelForSelection = "", this.inputControl.value = "", t && this.inputControl.dispatchEvent && this.inputControl.dispatchEvent(new Event("change", {
      bubbles: !0,
      cancelable: !0
    }));
    const e = this.shadowRoot?.querySelector("cds-button");
    e && e.focus();
  }
  updateLabelAndFocus(t) {
    t && t.length ? this.buttonLabelForSelection = t.length > 1 ? `${t.length} ${this.i18n.files}` : t[0].name : this.clearFiles(!1);
  }
}
t([o()], a.prototype, "i18n", void 0), t([s()], a.prototype, "buttonLabel", void 0), t([n()], a.prototype, "buttonLabelForSelection", void 0), t([n()], a.prototype, "fixedControlWidth", void 0), t([n()], a.prototype, "supportsPrefixSuffixActions", void 0);
export { a as CdsFile };
