import { __decorate as t } from "tslib";
import { html as e } from "lit";
import { listenForAttributeChange as s, globalStyle as o, state as i } from "@cds/core/internal";
import { CdsControl as r } from "@cds/core/forms";
import { inputStyles as l } from "@cds/core/input";
import p from "./select.global.scss.js";
import n from "./select.element.scss.js";
class u extends r {
  constructor() {
    super(...arguments);
    this.globalStyles = p, this.multiple = !1, this.size = !1;
  }
  get suffixDefaultTemplate() {
    return e`<cds-button-expand expanded readonly="readonly"></cds-button-expand>`;
  }
  static get styles() {
    return [...super.styles, l, n];
  }
  firstUpdated(t) {
    super.firstUpdated(t), this.multiple = this.inputControl.hasAttribute("multiple"), this.observers.push(s(this.inputControl, "multiple", t => this.multiple = null !== t)), this.size = this.inputControl.hasAttribute("size"), this.observers.push(s(this.inputControl, "size", t => this.size = null !== t));
  }
}
t([o()], u.prototype, "globalStyles", void 0), t([i({
  type: Boolean,
  reflect: !0
})], u.prototype, "multiple", void 0), t([i({
  type: Boolean,
  reflect: !0
})], u.prototype, "size", void 0);
export { u as CdsSelect };
